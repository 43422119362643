@import "../ThemeVariables.less";

@media screen and (min-width: 320px) and (max-width: 780px) {
  .r-mt-20 {
    margin-top: 20px !important;
  }
  .right-20 {
    right: 20px !important;
  }
  .nr-mt-20 {
    margin-top: -20px !important;
  }
  .r-mt-10 {
    margin-top: 10px !important;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .login-wrapper,
  .signup-wrapper,
  .confirm-signup-wrapper,
  .reset-password-wrapper {
    .form-footer-container {
      align-items: baseline !important;
      padding-top: 60px;
    }
  }
  .support-btn.bottom-75 {
    bottom: 75px;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
    width: 40px;
    height: 40px;
    img {
      width: 20px;
    }
  }
  .menu-icon {
    display: block !important;
    position: absolute;
    top: 8px;
    border-radius: 5px;
    background: @primary;
    z-index: 999999;
    right: 20px;
    height: auto;
    padding: 5px 8px 5px 8px;
    width: auto;

    button {
      span {
        display: none;
      }
    }
  }
  .menu-icon:hover,
  .menu-icon:focus {
    background: @primary !important;
  }

  .menu-icon-faq {
    display: block !important;
    position: absolute;
    top: 8px;
    border-radius: 5px;
    background: #f2f9fd;
    z-index: 999999;
    right: 70px;
    height: auto;
    padding: 5px 8px 5px 8px;
    width: auto;
  }
  .menu-icon-faq:hover,
  .menu-icon-faq:focus {
    background: #f2f9fd !important;
  }

  .main-menu-sidebar {
    .ant-drawer-content {
      background: @primary;
      color: white;
    }
    .ant-drawer-header {
      border-bottom: 0;
    }
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
      .ant-drawer-close {
        margin-right: 0;
        color: white;
      }
      .user-imges {
        max-width: 45px;
        max-height: 45px;
        width: 45px;
        height: 45px;
      }
      .user-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        .button-font {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #b5b5b5;
        }
        img.address-icon {
          margin: 0;
          height: 14px;
          filter: brightness(1.7);
          -webkit-filter: brightness(1.7);
        }
      }
    }
    .ant-drawer-body {
      position: relative;
      padding: 0 25px !important;
      .responsive-menu-list {
        background: transparent;
        color: white;
        border-right: 0;
        height: calc(100vh - 150px);
        overflow-y: scroll;
        overflow-x: hidden;
        .menu,
        .menu .ant-menu-submenu-title {
          padding-left: 0 !important;
          padding-top: 0 !important;
        }
        .sub-menu {
          padding-left: 24px !important;
        }
        .inner-sub-menu {
          padding-left: 48px !important;
        }
        .ant-menu-sub.ant-menu-inline {
          background: transparent;
          color: white;
        }
        li[role="menuitem"],
        div[role="menuitem"] {
          border-bottom: 1px solid #4e6e83;
          margin-bottom: 0 !important;
        }
        .ant-menu-title-content {
          color: #fff;
          font-weight: 500;
        }
        .ant-menu-submenu-arrow {
          color: white !important;
        }
        .ant-menu-item-selected {
          background: transparent !important;
          &::after {
            border-right: 0;
          }
        }
      }
      .invite-btn {
        display: flex;
        justify-content: center;
        margin: auto;
        border-radius: 6px;
        background: #fff;
        font-weight: 600;
        width: 150px;
      }
    }
  }
  .global-searchbar {
    width: calc(100% - 30px) !important;
  }
  .ant-layout-content {
    padding-top: 10px;
    padding-bottom: 70px;
    overflow: scroll !important;
  }

  .mobile-card {
    margin: 0px 12px 0px 12px;
  }
  .procedure-card {
    .ant-card-body {
      padding: 12px !important;
    }
  }
  // .login-wrapper {
  //   .form-footer-container {
  //     // align-items: flex-end !important;
  //   }
  // }
  // .ant-card.ant-card-bordered {
  //   border-radius: 0 !important;
  // }

  .userp-version {
    display: unset !important;
    text-align: center;
    padding: 0 30px 70px;
  }
  .single-list-card-surgery {
    .surgery-profile-card {
      img.doctor-img {
        width: 70px !important;
        height: 70px !important;
        min-width: 70px !important;
      }
    }

    // p.single-address-line {
    //   margin-top: 0px !important;
    //   margin-left: 10px;
    // }
    .app-bottom-margin {
      margin-bottom: 25px !important;
    }
  }
  .ant-breadcrumb {
    padding: 0 25px;
  }
  .search-result {
    .ant-list {
      .ant-avatar {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .ant-list-vertical {
      .ant-list-item-meta {
        margin-top: 16px;
        align-items: center;
      }
    }
    .ant-list-item-meta-content {
      flex-direction: column;
      align-items: flex-start !important;
      .ant-list-item-meta-title {
        margin-bottom: 0;
        line-height: 17px !important;
      }
      .ant-list-item-meta-description {
        margin-left: 0 !important;
      }
    }
  }
  .body-area,
  .body-area-surgery {
    .service-box {
      height: 100% !important;
      padding: 12px !important;
      p {
        width: 100% !important;
      }
    }
  }
  .body-area-ic {
    .service-box {
      height: 100% !important;
      padding: 12px !important;
      p {
        width: 100% !important;
      }
    }
  }

  .modal-bottom {
    min-width: 100%;
    position: fixed;
    left: 0;
    bottom: 0 !important;
    top: auto !important;
    margin-bottom: 0;
  }
  .userprofile-tab {
    .ant-tabs-nav-wrap {
      .ant-tabs-tab-btn {
        span {
          font-size: 13px !important;
        }
      }
    }
  }
  .headline {
    .sub-head {
      gap: 15px;
      padding: 20px 12px !important;
    }
  }
  .profile-card .ant-card-grid {
    padding: 12px !important;
  }
  .doctor-profile {
    // padding-bottom: 0 !important;
    img.doctor-img {
      width: 110px !important;
      height: 110px !important;
    }
    .d-flex.gap-4 {
      gap: 1rem;
    }
  }
  .doctor-surgery {
    h4.doctor-name {
      line-height: 22px;
      margin-bottom: 9px;
    }
    img.surgery-img {
      width: 85px !important;
      height: 85px !important;
    }
  }
  .request-appointment {
    h6 {
      text-align: center;
    }
    .ant-form-item {
      margin-bottom: 12px !important;
    }
    span.default-msg {
      margin-top: 0px !important;
    }
  }

  .align-left {
    text-align: left !important;
  }

  .doctor-overview {
    border-bottom: 1px solid #fff;
    // p {
    //   display: flex;
    // }
    .location {
      height: 19px;
    }
  }
  .main-tabs.responsive-tab .ant-tabs-nav {
    position: fixed;
    bottom: -1px !important;
    left: 0;
    z-index: 9;
    right: 0;
    width: 100%;
    background: @primary;
    margin-bottom: 0 !important;
    border-radius: 0;
  }
  .procedure-details-page {
    padding-bottom: 50px !important;
  }
  .procedure-form-modal {
    .modal-header {
      flex-direction: column;
    }
  }
  .footer {
    .footer-text {
      text-align: center;
    }
  }
  .speciality-selectbar {
    width: 150px !important;
  }
  .doc-dropdown-bodyPart {
    width: calc(100% - 170px) !important;
  }

  .res-login {
    position: fixed;
    bottom: 0;
  }

  .form-logo-container {
    img {
      margin-top: 0px !important;
    }
  }

  .right-col {
    align-items: center !important;
  }
  .left-col {
    margin-top: 20px;
    align-items: center !important;
  }

  .services .ant-card.ant-card-bordered.mb-2 {
    padding-bottom: 0px !important;
    // min-height: 72vh !important;

    p.suggest-btn {
      margin-top: 20px !important;
    }
  }

  .reward-success-modal .ant-modal-body {
    padding: 20px !important;
  }
  .n-m20 {
    h6 {
      margin-top: 0px !important;
    }
  }

  .copy-link-row {
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .hide-clipboard {
    display: block;
  }

  .ant-timeline-item {
    padding-bottom: 0px !important;
  }

  p.single-address-line {
    margin-top: -5px !important;
  }

  .stepper-reward {
    h4.doctor-name {
      line-height: 30px;
      text-align: center;
    }
    h6 {
      text-align: center;
    }
  }

  .procedure-detail-page {
    margin-top: 10px !important;
  }

  .mobile-sub-header {
    flex-direction: column !important;
    text-align: center !important;
    height: 180px !important;
    justify-content: flex-start !important;
  }
  .user-ic {
    margin-top: -15px !important;

    span.ant-avatar.ant-avatar-circle.ant-avatar-icon.user-avt {
      width: 80px !important;
      height: 80px !important;
      line-height: 80px !important;
      font-size: 40px !important;
    }

    button.ant-btn.ant-btn-circle.ant-btn-default.btn-primary-dual-color.upload-btn {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }

    .ant-btn > .anticon {
      line-height: 0 !important;
    }

    .ant-btn-circle {
      min-width: 20px !important;
    }

    .upload-btn {
      height: 20px !important;
      svg {
        width: 10px !important;
      }
    }

    // svg {
    //   width: 10px !important;
    // }
  }

  .hide-version-no {
    display: none !important;
  }

  .contact-us-modal {
    .ant-modal-content {
      padding: 0px !important;
    }
  }

  .doc-first-step {
    .ant-input-group.ant-input-group-compact {
      display: flex !important;
      flex-direction: column !important;
    }
  }

  .hr-invite-modal .ant-modal-body {
    padding: 0px !important;
    h6 {
      margin-bottom: 0px !important;
    }
  }

  .doc-dropdown-bodyPart {
    margin-top: -1px;
    width: 100% !important;
  }

  .speciality-selectbar {
    width: 100% !important;
    margin-top: -10px !important;
  }
  .br-40 {
    border-radius: 6px !important;
  }
  .mobile-card {
    .ant-card-body {
      padding: 15px !important;
    }
  }

  .r-mt-5 {
    margin-top: 5px !important;
  }

  .remove-side {
    margin-left: -10px !important;
    padding-left: 0px !important;
  }

  .list-content {
    margin-top: 0px !important;
  }

  .doctor-profile {
    margin-top: 0px !important;
  }
  .profile-tabs {
    margin-top: 10px !important;
  }
  // .request-appointment h6 {
  //   text-align: left !important;
  // }
  .border-bottom-only {
    border: 1px solid #d9d9d9 !important;
    // border-bottom: 1px solid #d9d9d9;
  }
  .main-search {
    border-radius: 6px !important;
    height: 32px !important;
  }
  .specialty-dropdown {
    border: 0.5px solid #d9d9d9 !important;
  }

  .r-mb-10 {
    margin-bottom: 10px !important;
  }

  // .menubar-faq {
  //   background-color: red !important;
  //   // width: 80px;
  // }
}

@media screen and (min-width: 577px) {
  .menu-icon {
    display: none !important;
  }
  .menu-icon-faq {
    display: none !important;
  }
}

@media screen and (min-width: 760px) and (max-width: 1024px) {
  .menu-icon {
    display: none !important;
  }
  .menu-icon-faq {
    display: none !important;
  }
  .doctor-profile {
    padding-bottom: 0 !important;
  }
  .profile-tabs {
    .ant-tabs-tab {
      padding: 0 0 12px !important;
    }
  }
  .procedure-detail-page {
    padding-bottom: 52px !important;
  }
  .userprofile-tab {
    .ant-tabs-nav {
      width: 75% !important;
    }
  }
  .employer-profile-card {
    .ant-card-grid {
      width: 70% !important;
    }
    .ant-card-grid.profile-bold {
      width: 30% !important;
    }
  }
  // .top-procedures {
  //   margin: 20px 0 0 !important;
  //   width: unset !important;
  // }
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
  .body-area-surgery {
    .service-box {
      height: 265px !important;
      p {
        width: 100% !important;
      }
    }
  }
  .body-area-ic {
    .service-box {
      height: 265px !important;
      p {
        width: 100% !important;
      }
    }
  }
}

