.mt-2 {
  margin-top: 1rem;
}

.cancel-sub {
  color: #dc0a16;
}

p.cancel-btn {
  left: 0;
  right: 0;
  font-size: 16px;
  font-weight: 500;
  button {
    border: 0;
    background: transparent;
    color: #dc0a16;
    box-shadow: none;
    text-shadow: none;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    &:hover,
    &:focus {
      background: transparent !important;
      color: #dc0a16 !important;
      transform: scale(1.05);
    }
  }
}

.user-appointment {
  .ant-divider-horizontal {
    margin: 10px 0px 10px 0px !important;
  }
}
