@import "../../../../../ThemeVariables.less";

.reset-password-wrapper {
  .reset-password-page-image {
    width: 100%;
    height: 100vh;
    background-image: url("./../../../../assets/img/signupPageImage.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  .form-footer-container {
    display: flex;
    align-items: center;
    background-color: #fdfdfd;
    height: 100vh;
    overflow: scroll;
    position: relative;

    .form-logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .bazar-logo {
        margin-top: 30px;
      }

      .reset-password-title {
        margin-top: 30px;
      }

      .reset-password-form label {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #646464;
      }
      .reset-password-form .ant-form-item-label {
        padding-bottom: 6px;
      }
      .email-form-item {
        margin-bottom: 20px;
      }

      .email {
        display: flex;
        align-items: center;
        padding: 5px 12px;
        width: 100%;
        // height: 42px;
        border: 1px solid #adadad;
        border-radius: 3px;
      }

      .reset-password-button {
        margin: 30px 0px;
        width: 100%;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: @primary;
        border-radius: 6px;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
      }
    }

    .footer-text {
      display: block;
      padding-bottom: 30px;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #646464;
    }
  }
}
.confirm-signup {
  margin: 30px 0px;
  width: auto;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: @primary;
  border-radius: 6px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

