@import "../../../../../ThemeVariables.less";

.doctor-profile {
  margin-top: 15px;
  border-radius: 11px !important;
  padding-bottom: 52px;
  img.doctor-img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 150px;
    border: 3px solid @list-border;
  }
  img.image {
    width: 140px;
    height: 140px;
  }
  img.surgery-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 150px;
    border: 3px solid @list-border;
  }

  h4.doctor-name {
    font-weight: 700;
    font-size: 1.5rem;
    color: @primary;
    margin-bottom: 0;
  }
  h6 {
    font-size: 1.125rem;
    color: @dark-gray;
  }
  .doctor-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 1.1rem;
      font-weight: 600;
      img {
        margin-right: 7px;
        margin-bottom: 2px;
        height: 17px;
      }
      span {
        margin-left: 7px;
      }
    }
  }
}
.profile-tabs {
  margin-top: 20px;
  border-radius: 11px !important;
  margin-bottom: 20px;
  .ant-tabs-tab {
    font-size: 1.125rem;
    color: @dark-gray;
    font-weight: 600;
    img {
      margin-right: 10px;
      height: 20px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    color: @primary;
    span {
      color: @primary !important;
    }
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: @primary;
  }
  .ant-tabs-nav {
    position: absolute;
    top: -98px;
  }
}
.doctor_detail {
  margin-top: 10px;
}
// @media screen and (max-width: 768px) {
//   .profile-tabs .ant-tabs-nav {
//     // position: static;
//   }
// }

.doctor-surgery {
  // .ant-collapse-item {
  //   margin-bottom: 20px !important;
  // }
  margin-top: 10px;
  border-radius: 11px !important;
  // padding-bottom: 52px;

  .ant-collapse-arrow {
    height: 10px;
  }
  .location {
    margin-right: 6px;
  }

  .space-between {
    justify-content: space-between;
    display: flex;
  }

  img.surgery-img {
    width: 93px;
    height: 93px;
    object-fit: cover;
    border-radius: 150px;
    border: 3px solid @list-border;
  }

  h4.doctor-name {
    font-weight: 700;
    font-size: 1.5rem;
    color: @primary;
    margin-bottom: 0;
  }
  h6 {
    font-size: 1.125rem;
    color: @dark-gray;
  }
  .doctor-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 1.1rem;
      font-weight: 600;
      img {
        margin-right: 7px;
        margin-bottom: 4px;
        height: 16px;
      }
      span {
        margin-left: 7px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 780px) {
  .profile-tabs .ant-tabs-nav {
    top: -78px !important;
  }
}

