@media screen and (min-width: 320px) and (max-width: 780px) {
  .separator-on {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    flex-direction: row !important ;
    padding: 0px !important;
    height: 20px !important;
    img {
      transform: rotate(90deg);
    }
  }

  .onboard-modal {
    h6 {
      margin-top: 10px;
    }
    // align-items: center !important;
    // justify-content: center !important;
    img {
      margin: 0 !important;

      // margin-right: 30px;
      max-width: 30% !important;
    }
    span {
      text-align: center !important;
    }
  }
  .onboard-wrapper {
    height: unset !important;
  }
  .onboarding-page {
    height: unset !important;

    img {
      max-width: 40% !important;
    }
    h6 {
      margin-top: 20px !important;
    }
    // background-color: red !important;
  }

  .right-col {
    align-items: center !important;
  }
  .left-col {
    margin-top: 20px;
    align-items: center !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
}

