.ant-tabs-nav-wrap {
  margin-top: "-20px";
}
.ant-tabs-tab {
  font-family: "Roboto" !important;
  color: #00274c !important;
  font-weight: 450 !important;
  size: "20px";
  line-height: "20px";
}

.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    font-family: "Roboto" !important;
    color: #00274c !important;
    font-weight: 600 !important;
    size: "20px";
    line-height: "20ox";
  }
}

.ant-tabs-ink-bar {
  background-color: #63c0da !important;
}
// .ant-tabs-tab-btn {
//   color: red !important;
// }

