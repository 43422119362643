@import "../../../../../ThemeVariables.less";
.procedures {
  .ant-table-wrapper {
    margin-top: 30px;
  }
  .ant-table-thead > tr > th {
    background: @secondary;
    color: @dark-gray;
    font-size: 1rem;
    font-weight: 600;
    border-right: 0 !important;
  }
  .ant-table-tbody > tr > td {
    color: @dark-gray;
    color: @dark-gray;
    font-weight: 500;
    border-right: 0 !important;
    vertical-align: top;
    a {
      color: @primary;
      // border-bottom: 1px solid;
      font-weight: 600;
    }
    &:last-child {
      color: @green;
    }
  }
  table {
    border-right: 1px solid #f0f0f0;
  }
}
.procedure-tabs {
  .ant-tabs-tab {
    img {
      margin-right: 5px;
      height: 18px;
    }
  }
}
.procedure-detail-page {
  padding-bottom: 0 !important;
 
  .doctor-img {
    border-radius: 50% !important;
    border: none !important;
  }

  .doctor-detail {
    p {
      font-weight: unset !important;
      font-size: unset !important;
      margin-bottom: 0;
    }
  }
  .price-box {
    background: @light-gray;
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 2px;
    text-align: center;
    h5,
    h3 {
      margin: 0 !important;
    }
    h3 {
      color: @primary;
    }
    .mb-5 {
      margin-bottom: 5px !important;
    }
  }
  .blue-box {
    background: rgba(104, 187, 227, 0.20);

    ;
    border: 1px solid #63C0DA;
    border-radius: 5px;
    margin-top: 10px;
  }
}
.procedure-detail-tabs {
  background: transparent;
  margin-bottom: 0 !important;
  .ant-card-body {
    padding: 0;
  }
  .ant-tabs-tab {
    a {
      color: @primary !important;
    }
  }
  .ant-tabs-nav {
    position: absolute;
    top: -75px !important;
    left: 24px;
  }
}

.procedure-blur {
  pointer-events: none;
  cursor: default;
  user-select: none;
  filter: blur(8px);
}

// .procedure-bg-text {
//   h1 {
//     color: #fff;
//     font-weight: 700;
//     font-size: 36px;
//   }
//   background-color: rgb(0, 0, 0); /* Fallback color */
//   background-color: rgba(0, 0, 0, 0.6); /* Black w/opacity/see-through */

//   font-weight: bold;
//   border-radius: 10px;
//   height: 15%;
//   // border: 5px solid @primary;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 2;
//   width: 80%;
//   padding: 20px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.procedure-bg-text {
  h1 {
    color: #fff;
    font-weight: 700;
    font-size: 36px;
  }
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/opacity/see-through */

  font-weight: bold;
  border-radius: 10px;
  height: 15%;
  // border: 5px solid @primary;
  position: absolute;
  top: 73%;
  left: 38%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.procedure-details {
  h3 {
    color: #1a1a1a;
    font-weight: bold;
  }
  h5 {
    color: #4d4d4d;
  }
  .ant-card {
    border-radius: 10px !important;
  }
  .list {
    list-style-position: outside;
    padding-left: 10px;
    list-style: none;
    li {
      padding-left: 15px;
      position: relative;
      font-size: 15px;
      font-weight: 600;
      color: #4d4d4d;
      line-height: 30px;
    }
    li:before {
      content: "•";
      font-size: 16px;
      color: @primary;
      position: absolute;
      left: 0;
      top: -2px;
    }
  }
  .faq_tabs {
    margin-top: 30px;
    .ant-collapse-item {
      background-color: #fff !important;
      position: relative;
      font-weight: 600;
    }
    .ant-collapse-header {
      padding: 10px !important;
    }
    .ant-collapse-content-box {
      p {
        color: #161616;
        font-size: 15px;
        font-weight: 500;
      }
    }
    .ant-collapse-expand-icon {
      display: none;
    }
    .ant-collapse-header span.ant-collapse-header-text {
      font-weight: 600;
      color: @primary;
      font-size: 1.1rem;
    }
    .ant-collapse-header span.ant-collapse-header-text::before {
      content: "";
      height: 6px;
      width: 6px;
      background-color: @primary;
      border-radius: 5px;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 2px;
    }
    .ant-collapse-borderless > .ant-collapse-item {
      background-color: #fff !important;
      position: relative;
      &::after {
        content: "+";
        position: absolute;
        right: 10px;
        top: 4px;
        font-size: 1.2rem;
        font-weight: 600;
        transition: 0.5s;
        width: 13px;
        text-align: center;
      }
    }
    .ant-collapse-item.ant-collapse-item-active {
      &::after {
        content: "-" !important;
        font-size: 1.2rem;
        font-weight: 600;
        transition: 0.5s;
        width: 13px;
        text-align: center;
      }
    }
  }
  .list-content {
    margin-top: 30px;
    padding-bottom: 30px;
    .single-list-card {
      border-radius: 10px;
      padding: 30px 20px;
      .rat-box {
        p {
          img {
            margin-bottom: 4px;
            height: 17px;
            width: 17px;
          }
        }
      }
    }
  }
  .find-doc-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 55px;
    .icon {
      color: #fff;
      margin-right: 10px;
      height: 23px;
    }
  }
  .border-btn {
    border: 1px solid @primary;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #fff;
    color: @primary;
    padding: 6px 23px;
    font-weight: 600;
  }

  .top-procedure {
    .ant-card-body {
      padding: 18px;
    }
    .header {
      background: #f2f9fd;
      border-radius: 5px;
      padding: 10px 20px;
    }
    .arrow-icon {
      color: #acacac;
    }
    .ant-list-item-meta-title {
      a {
        color: #161616;
        font-weight: 600;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
.scrollbox {
  // height: 35vh;
  position: relative;
  scroll-behavior: smooth;
  overflow: scroll;
}

@media screen and (max-width: 576px) {
  .procedures {
    .ant-table-content {
      overflow-x: scroll;
      width: 100%;
      &::-webkit-scrollbar {
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.no-margin p,
.no-margin h1,
.no-margin h2,
.no-margin h3,
.no-margin h4,
.no-margin h5,
.no-margin span {
  margin-left: 0px !important;
}

.reference-select {
  span.ant-select-arrow {
    margin-top: -12px;
  }
}

.procedure-table {
  .ant-pagination {
    margin: 16px 0 0 !important;
  }
  .ant-table-content {
    overflow: scroll !important;
  }
}

.reward-amount {
  // font-size: 24px !important;
  font-weight: 700 !important;
}

.stepper-reward-left {
  .ant-steps-item-content {
    margin-left: -300px !important;
  }
  .ant-steps-item-title {
    display: flex !important;

    justify-content: right !important;
    margin-right: 43px !important;
    color: @primary !important;
    font-weight: 700 !important;
  }
}

.stepper-reward-right {
  .ant-steps-item-content {
    margin-right: -300px !important;
  }
  .ant-steps-item-title {
    margin-left: 35px !important;

    display: flex !important;
    margin-right: 43px !important;
    color: @primary !important;
    font-weight: 700 !important;
  }
  .ant-steps-item-description {
    margin-left: 35px !important;
  }
}

.stepper-reward {
  .reward-img-title {
    max-width: 100%;
  }
  h4.doctor-name {
    font-weight: 700;
    font-size: 1.5rem;
    color: @primary;
    margin-bottom: 0;
    // line-height: 30px;
    text-align: center;
  }
  h6 {
    font-size: 1.125rem;
    color: #646464;
    margin-bottom: 0;
    font-weight: 700;
  }
  .doctor-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 1.1rem;
      font-weight: 600;
      img {
        margin-right: 7px;
        margin-bottom: 5px;
      }
      span {
        margin-left: 7px;
      }
    }
  }
}

.reward-claim {
  span {
    font-size: 14px;
    color: @primary;
    font-weight: 700;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    color: #646464;
  }
  .step-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: @primary; /* Replace with your desired fill color */
  }
  .step-circle-yellow {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #68BBE3;
  }
  .ant-timeline-item-content {
    padding-right: 10px !important;
  }
  .left-pad {
    .ant-timeline-item-content {
      padding-left: 10px !important;
    }
  }
}

.procedure-form-modal {
  .ant-modal-header {
    border-bottom: 0 !important;
  }
  .ant-modal-title {
    text-align: center;
    color: #161616;
    font-weight: bolder;
    text-transform: capitalize;
  }
  .ant-select-selector,
  .ant-input,
  .ant-picker {
    border-radius: 5px !important;
  }
  label {
    color: #646464;
    font-weight: 600;
  }
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
}

// .vertical-line-row {
//   display: flex;
// }

// .vertical-line-column {
//   position: relative;
//   padding-left: 10px; /* Add some space for the line */
// }

// .vertical-line-column::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 1px;
//   background-color: #ccc; /* Set the color of the vertical line */
// }

// .label {
//   font-size: 14px;
//   line-height: 18px;
//   font-weight: 600;
// }

// .amount {
//   margin-top: 5px; /* Add space between label and amount */
//   font-size: 14px; /* Adjust font size as needed */
// }

.vertical-line-row {
  display: flex;
  justify-content: space-between;
}

.vertical-line-column {
  position: relative;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.avg-sav {
  color: @primary !important;
  font-weight: 700 !important;
}

.vertical-line-column .label {
  font-weight: 700;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 5px;
}

.vertical-line-column .amount {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
}

.vertical-line-column:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background-color: #ccc; /* Set the color of the vertical line */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .vertical-line-column {
    align-items: flex-start; /* Align items to the start vertically */
    text-align: left; /* Left-align text on smaller screens */
  }
}

.mobile-procedure {
  h1 {
    font-size: 1rem;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
    font-weight: 550;
  }
}

.ant-divider.ant-divider-horizontal.procedure-divider {
  margin: 15px 0px -5px 0px !important;
}

.procedure-prices {
  display: flex;
  flex-direction: column;

  .pro-mt-15 {
    margin-top: 15px;
  }
}

.find-doc-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 55px;
  .icon {
    color: #fff;
    margin-right: 10px;
    height: 23px;
  }
}

.show-procedure-btn {
  display: none;
}

.reward-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.procedure-card-list {
  border-radius: 6px;
  // border: 1px solid @primary;
  padding: 20px;
  background: @secondary;
  // margin-top: 20px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;

  .surgery-profile-card {
    text-align: unset !important;
    width: 100%;
    // margin-top: 15px;
    border-radius: 11px !important;
    img.doctor-img {
      width: 90px;
      height: 90px;
      min-width: 90px;
      object-fit: cover;
      border-radius: 150px;
      border: 1px solid @list-border;
    }

    h4.doctor-name {
      font-weight: 700;
      font-size: 20px;
      color: @primary;
      margin-bottom: 0;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .space-between {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 35px;
    }
    .tag-list {
      margin-bottom: 20px;
    }
    .specialty-tags {
      font-weight: 700;
      margin-top: 10px;
      font-size: 14px;
      border-radius: 50px;
      color: #e7a500;
      border: 1px solid #e7a500;
      display: inline-flex;
      align-items: center;
      background: #fff8e8;
    }
    .s-tags {
      width: 100%;
    }
    p.single-address-line {
      color: @mid-gray;
      font-size: 16px;
      font-weight: 500;
      margin-top: -10px;
      margin-bottom: 0px !important;
      display: flex;
      gap: 2px;
    }
    .learn-more-btn {
      margin-top: -20px;
    }
    h6 {
      font-size: 1.125rem;
      color: @dark-gray;
    }
    .doctor-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 1.1rem;
        font-weight: 600;
        img {
          margin-right: 7px;
          margin-bottom: 5px;
        }
        span {
          margin-left: 7px;
        }
      }
    }
  }

  .imaging-center-card {
    img.doctor-img {
      width: 80px;
      height: 80px;
      min-width: 80px;
    }
  }

  .rat-box {
    p {
      font-size: 1rem;
      color: @dark-gray;
      // margin-right: 10px;
      font-weight: 600;
      img {
        margin-bottom: 2px;
        height: 16px;
        width: 16px;
      }
    }
  }
}

// .pr-first-step {
//   margin-left: 70px !important;
// }


