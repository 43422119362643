@import "../../../../../ThemeVariables.less";

.faq_tabs {
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }

  .ant-tabs-ink-bar {
    background-color: transparent;
  }
  .ant-tabs-nav {
    &::before {
      border-bottom: 0 !important;
    }
    .ant-tabs-nav-list {
      background: transparent !important;
      box-shadow: none !important;
      border: 1px solid;
      border-radius: 6px !important;
      padding: 0 !important;
      // overflow: hidden;
      width: 100%;
      .ant-tabs-tab {
        display: flex !important;
        padding: 5px !important;
        border-radius: 0 !important;
        margin: 0 !important;
        width: 100%;
        // &:nth-child(2) {
        //   border-right: 1px solid @primary;
        //   border-left: 1px solid @primary;
        // }
        &:hover {
          background-color: transparent !important;
          color: @primary;
          font-weight: bolder;
        }
      }
      .ant-tabs-tab-btn {
        font-size: 1.1rem;
        font-weight: 500;
        margin: auto;
        display: flex !important;
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        background-color: @primary;
        border-radius: 3px !important;
        div {
          color: @white !important;
        }
        &:hover {
          background-color: @primary !important;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    .ant-collapse-borderless > .ant-collapse-item {
      background-color: #fff !important;
      position: relative;
      &::after {
        content: "+";
        position: absolute;
        right: 10px;
        top: 4px;
        font-size: 1.2rem;
        font-weight: 600;
        transition: 0.5s;
        width: 13px;
        text-align: center;
      }
    }
    .ant-collapse-item.ant-collapse-item-active {
      &::after {
        content: "-" !important;
        font-size: 1.2rem;
        font-weight: 600;
        transition: 0.5s;
        width: 13px;
        text-align: center;
      }
      .ant-collapse-header {
        padding: 10px 10px 0 !important;
      }
    }
    .ant-collapse-expand-icon {
      display: none;
    }
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 10px !important;

        span.ant-collapse-header-text {
          font-weight: 500;
          color: @primary;
          font-size: 1.1rem;
          &::before {
            content: "";
            height: 6px;
            width: 6px;
            background-color: @primary;
            border-radius: 5px;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 2px;
          }
        }
      }
      p {
        margin-bottom: 0;
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .ant-tabs.ant-tabs-top.ant-tabs-mobile.faq_tabs {
    .ant-tabs-nav {
      padding: 0px !important;
    }
  }
  .faq_tabs {
    .ant-tabs-nav {
      width: 100% !important;
      margin: auto;
      position: unset !important;
      border-radius: 3px !important;
      background-color: unset !important;
      padding: 0px !important;
    }
    .ant-tabs-tab-btn span {
      color: #000;
    }
    .ant-tabs-tab.ant-tabs-tab-active span {
      color: #fff !important;
    }
    .ant-tabs-nav-list {
      margin-left: 0 !important;
      padding: 0px !important;
      // width: auto !important;
      // .ant-tabs-tab {
      //   width: auto !important;
      // }
    }
    // .ant-tabs-nav {
    // width: calc(100% + 48px);
    // margin-left: -24px;
    // }
  }
}

@media screen and (min-width: 768px) {
  // .faq_tabs .ant-tabs-nav .ant-tabs-nav-list {
  //   overflow: hidden;
  // }
  // .ant-tabs-nav{
  //   width: 100%;
  //   margin: auto;
  // }
}

