@import "../../.././././././../ThemeVariables.less";

.surgical-testimonial {
  margin-top: 25px;
  .testimonial-single {
    padding: 20px;
    background: @secondary;
    margin-top: 5px;
    img {
      margin-bottom: 20px;
    }

    p {
      font-size: 1rem;
      color: @dark-gray;
    }

    b {
      font-size: 1.125rem;
      margin-right: 10px;
      // span {
      //   letter-spacing: -3px;
      // }
    }

    span.anticon.anticon-star {
      font-size: 1.125rem;
      color: @dark-gray;
    }
  }
  .testimonial-nav {
    button {
      width: 25px;
      height: 25px;
      span {
        font-size: 12px;
      }
    }
  }
}

// span.anticon.slick-arrow {
//     border: 1px solid #000;
//     height: 30px;
//     width: 30px;
//     z-index: 1;
// }

// span.anticon.slick-arrow svg path {
//     fill: #000 !important;
// }
// span.anticon.slick-arrow svg {
//     width:15px;
//     height:30px;
// }

.top-procedures {
  // margin: 24px;
  // width: calc(100% + 48px);
  .ant-table-wrapper {
    margin-top: 0 !important;
  }
}

.single-list-card-surgery {
  border-radius: 6px;
  // border: 1px solid @primary;
  padding: 20px;
  background: @secondary;
  // margin-top: 20px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;

  .surgery-profile-card {
    text-align: unset !important;
    width: 100%;
    // margin-top: 15px;
    border-radius: 11px !important;
    img.doctor-img {
      width: 90px;
      height: 90px;
      min-width: 90px;
      object-fit: cover;
      border-radius: 150px;
      border: 1px solid @list-border;
    }

    h4.doctor-name {
      font-weight: 700;
      font-size: 20px;
      color: @primary;
      margin-bottom: 0;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .space-between {
      display: flex;
      justify-content: space-between;
      margin-bottom: 35px;
    }
    .tag-list {
      margin-bottom: 20px;
    }
    .specialty-tags {
      font-weight: 700;
      margin-top: 10px;
      font-size: 14px;
      border-radius: 50px;
      color: #e7a500;
      border: 1px solid #e7a500;
      display: inline-flex;
      align-items: center;
      background: #fff8e8;
    }
    .s-tags {
      width: 100%;
    }
    p.single-address-line {
      color: @mid-gray;
      font-size: 16px;
      font-weight: 500;
      margin-top: -10px;
      margin-bottom: 0px !important;
      display: flex;
      gap: 2px;
    }
    .learn-more-btn {
      margin-top: -20px;
    }
    h6 {
      font-size: 1.125rem;
      color: @dark-gray;
    }
    .doctor-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 1.1rem;
        font-weight: 600;
        img {
          margin-right: 7px;
          margin-bottom: 5px;
        }
        span {
          margin-left: 7px;
        }
      }
    }
  }

  .imaging-center-card {
    img.doctor-img {
      width: 80px;
      height: 80px;
      min-width: 80px;
    }
  }

  .rat-box {
    p {
      font-size: 1rem;
      color: @dark-gray;
      // margin-right: 10px;
      font-weight: 600;
      img {
        margin-bottom: 2px;
        height: 16px;
        width: 16px;
      }
    }
  }
}

.card-title-surgery {
  font-size: 24px;
  font-weight: 700;
  font-family: "Roboto";
  // line-height: 43.2px;
  color: @primary;
  margin-bottom: 0px !important;
}

.sub-title-box-surgery {
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #676767;
  line-height: 13px;
}

.specialty-dropdown {
  width: 100%;
  border: 1px solid @primary ;
  border-radius: 6px;
  // .ant-select-selector {
  //   height: 42px !important;
  // }
  // .ant-select-selector {
  //   height: 30px !important;
  // }
  span.ant-select-selection-item {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.request-app {
  display: grid;
  width: 100%;
  position: absolute;
  bottom: 0;

  left: 0;
  width: 100%;
}

