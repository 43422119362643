.empty-notification {
  p {
    color: #000;
    line-height: 24px;
    font-size: 1rem;
    font-weight: 500;
  }
}

.demo-loadmore-list {
  .ant-list-item-meta {
    align-items: center !important;
  }
  .ant-avatar-image {
    height: 46px;
    width: 46px;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0px !important;
  }
}

