@import "../../../ThemeVariables.less";

.searchbar:hover {
  border-color: @primary !important;
}

.referral-code {
  color: @primary;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
}

.switch-code {
  .ant-switch-checked {
    background-color: @primary !important;
  }
}

.searchbar:focus {
  border-color: @primary !important;
  box-shadow: none !important;
}

.searchbar {
  input {
    border-radius: 100px !important;
    position: relative;
    padding-left: 40px;
    border: 1px solid @primary;
  }
  .ant-input-group-addon {
    position: absolute;
    left: 5px !important;
    top: 0;
    bottom: 0;
    z-index: 9;
    margin: auto;
    border: 0;
    background: transparent;
    button {
      border: none;
      background: transparent;
    }
  }

  .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
  }
}

.text-center {
  text-align: center;
}

.mt-20 {
  margin-top: 20px;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  margin-top: 10px;
}
.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.text-primary {
  color: @primary !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.blue-border-btn {
  border: 1px solid @primary;
  border-radius: 6px;
  color: @primary !important;
  background-color: transparent;
  font-weight: 600;
  &:hover {
    background-color: @primary;
    color: white !important;
    border-color: @primary !important;
  }
}

.blue-border-btn:focus {
  color: @primary !important;
  background-color: transparent;
  border-color: @primary !important;
}

.blue-border-btn .export-btn {
  width: 142px;
}
.blue-border-btn .export-btn:hover {
  color: @primary;
  &:hover {
    color: white;
  }
}

.filter-border-btn {
  border: 1px solid @primary;
  border-radius: 6px;
  color: @primary;
  font-weight: 600;
  &:hover,
  &:focus {
    background-color: @primary;
    border-color: @primary;
  }
}
.filter-border-selection {
  border: 1px solid @primary;
  border-radius: 6px;
  color: @primary;
  font-weight: 600;
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: transparent;
  }
}
// .filter-border-btn .filter-option {
//   color: @primary !important;
//   background-color: transparent;
//   font-weight: 600;
// }

.blue-btn {
  background-color: @primary;
  border-color: @primary;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  &:hover {
    background-color: transparent;
    color: @primary;
    border-color: @primary;
  }
}

.blue-btn:focus {
  background-color: @primary;
  border-color: @primary;
  border-radius: 6px;
  color: white;
  font-weight: 600;
}

.table-header {
  button {
    margin-left: 10px;
  }
  .searchbar {
    .ant-input-group-addon {
      left: -8px !important;
    }
    .ant-input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0 !important;
    }
  }
  .ant-select-selection-placeholder {
    color: @primary;
  }
  .ant-dropdown-trigger {
    .anticon-down {
      transform: translateY(3px);
      font-size: 14px;
    }
  }
}

.ant-select-arrow {
  color: @primary;
}

.common-table {
  margin-top: 20px;
  table {
    border: 0.5px solid #e0e0e0;
    border-top: 0;
  }
  thead {
    th {
      background-color: #f2f9fd;
      font-weight: bolder;
    }
  }
  .ant-checkbox-inner {
    background: transparent;
    border-color: #525252;
    border-radius: 4px;
  }
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background: @primary;
    }
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: @primary;
  }
  // .ant-table-cell.ant-table-selection-column {
  //   padding-left: 18px;
  // }
  .actions {
    text-align: center;
    border-left: 0.5px solid #e0e0e0;
  }
  th.actions {
    border-left: 0;
  }
  a {
    color: black;
  }
  .ant-pagination-item-link {
    border: none;
    color: #161616;
    font-weight: bolder;
  }
  .ant-pagination-item {
    border: none;
  }
  .ant-pagination-item-active {
    background-color: @primary;
    a {
      color: white;
    }
  }
  td {
    color: #161616;
    font-weight: 500;
  }
}

.no-header {
  table {
    border: 0.8px solid #adadad;
    border-top: 0.8px solid #adadad;
  }
  .border-left {
    border-left: 0.8px solid #adadad;
  }
}

.form-modal {
  .ant-modal-header {
    border-bottom: 0 !important;
  }
  .ant-modal-title {
    text-align: center;
    color: #161616;
    font-weight: bolder;
    text-transform: capitalize;
  }
  .ant-select-selector,
  .ant-input,
  .ant-picker {
    border-radius: 6px !important;
  }
  label {
    color: #646464;
    font-weight: 600;
  }
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
}

.ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.ant-select-selector:hover {
  border-color: @primary;
}
.ant-select-selector:focus {
  border-color: @primary;
  box-shadow: none !important;
}
.ant-input:focus {
  border-color: @primary !important;
  box-shadow: none;
}
.ant-input-number {
  border-radius: 5px !important;
  width: 100%;
}

.ant-input-number:hover {
  border-color: @primary !important;
}

.ant-input-number:focus {
  border-color: @primary !important;
  box-shadow: none !important;
}
.ant-input:hover {
  border-color: @primary !important;
}

// .ant-select:hover {
//   border-color: @primary !important;
// }
// .ant-select:focus {
//   border-color: @primary !important;
//   box-shadow: none !important;
// }
// .ant-select:focus {
//   border-color: @primary !important;
//   box-shadow: none !important;
// }
// .ant-select-open:focus{
//   border-color: @primary !important;
// }

.back-btn {
  font-weight: bolder;
  font-size: 16px;
}

.border-radius-6px {
  border-radius: 6px;
}

.mt-3 {
  margin-top: 1.5rem;
}

.ant-select-selector,
.ant-input,
.ant-picker,
.ant-input-password {
  border-radius: 5px !important;
}

.update-form {
  label {
    font-weight: 600;
    color: #646464;
  }
  .searchbar {
    .ant-input {
      border-radius: 6px !important;
    }
  }
  .ant-upload {
    width: 100%;
    .ant-btn {
      width: 100%;
      height: 142px;
      font-size: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 35px;
        color: #646464;
      }
    }
  }
}

// .blue-btn[disabled] {
//   background-color: #52c41a;
//   color: #fff;
// }

.deny-btn {
  background-color: #ffd591;
  border-color: #ffd591;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  &:hover,
  &:focus {
    background-color: transparent;
    color: #ffd591;
    border-color: #ffd591;
  }
}

@media screen and (min-width: 300px) and (max-width: 780px) {
  .adminLayout {
    .sidebar {
      footer {
        display: none !important;
      }
    }
  }
  .mtr-20 {
    margin-top: 20px;
  }
  .responsive-hide {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
}

.tags-filter {
  p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px !important;
  }
  span {
    border-radius: 12px;
    background-color: #eaeaea;
    margin-left: 5px;
    color: #263238;
    font-weight: 500;
  }
}

.modal-title {
  margin-bottom: 0px !important;
}

.reward-input-box {
  margin-top: 0px !important;
}

.upload-p {
  font-size: 14px !important;
  font-weight: 400 !important;
}

