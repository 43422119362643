@import "../../../../../ThemeVariables.less";

.confirm-signup-wrapper {
  .confirm-signup-page-image {
    width: 100%;
    height: 100vh;
    background-image: url("./../../../../assets/img/signupPageImage.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  .form-footer-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fdfdfd;
    height: 100vh;
    overflow: scroll;

    .form-logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .bazar-logo {
        margin-top: 30px;
      }

      .instruction {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #646464;
        margin-bottom: 10px;
        margin-top: 30px;
      }
      .confirm-signup-form label {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #646464;
      }
      .confirm-signup-form .ant-form-item-label {
        padding-bottom: 6px;
      }
      .email-form-item {
        margin-bottom: 20px;
      }

      .code-form-item {
        margin-bottom: 10px;
      }

      .email,
      .code {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        width: 100%;
        // height: 42px;
        border: 1px solid #adadad;
        border-radius: 3px;
      }
      .resend-code {
        display: flex;
        justify-content: flex-end;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #646464;
      }
      .confirm-signup-button {
        margin: 20px;
        width: auto;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: @primary;
        border-radius: 6px;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
      }
    }

    .footer {
      .footer-text {
        // position: fixed;
        // bottom: 5px;
        padding-bottom: 30px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #646464;
      }
    }
  }
}

