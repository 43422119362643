@import "../../../../ThemeVariables.less";

.billing-cards-invite {
  h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: center;
  }
  .ant-divider-horizontal {
    margin: 10px 0px 10px 0px !important;
    // border: 0.5px solid #456d87;
  }
  .ant-radio-wrapper span {
    //   font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: baseline;
  }
  .strike-text {
    color: #bbb;
    font-weight: 600;
    margin-right: 10px;
  }
  .ant-tag {
    border-radius: 100px;
    background: #c1e5fd;
    border: none;
    font-weight: 600;
    height: 21px;
  }
  .pricing {
    font-size: 28px !important;
    font-weight: 700 !important;
    margin-right: 5px;
  }
  .c-pricing {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 39px;
    margin-right: 5px;
    padding-top: 5px;
    // margin-right: 5px;
  }
  .services {
    font-weight: 500;
    font-size: 0.875rem;
    img {
      margin-right: 10px;
      height: 14px;
    }
  }
  .members {
    // height: 36px;
    padding: 15px;
  }

  .emp-radio {
    padding: 0px !important;
    .ant-radio-wrapper {
      align-items: center;

      .ant-radio-inner {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.billing-cards-invite-dpc {
  h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: center;
  }
  .ant-divider-horizontal {
    margin: 10px 0px 10px 0px !important;
    // border: 0.5px solid #456d87;
  }
  .ant-radio-wrapper span {
    //   font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: baseline;
  }
  .strike-text {
    color: #bbb;
    font-weight: 600;
    margin-right: 10px;
  }
  .ant-tag {
    border-radius: 100px;
    background: #c1e5fd;
    border: none;
    font-weight: 600;
    height: 21px;
  }
  .pricing {
    font-size: 28px !important;
    font-weight: 700 !important;
    margin-right: 5px;
  }
  .c-pricing {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 39px;
    margin-right: 5px;
    padding-top: 5px;
    // margin-right: 5px;
  }
  .services {
    font-weight: 500;
    font-size: 0.875rem;
    img {
      margin-right: 10px;
      height: 14px;
    }
  }
  .members {
    // height: 36px;
    padding: 15px;
  }

  .emp-radio {
    padding: 0px !important;
    .ant-radio-wrapper {
      align-items: center;

      .ant-radio-inner {
        height: 20px;
        width: 20px;
      }
    }
  }
}
.column-content {
  border-right: 1px solid #000;
}

.employer-invite {
  border: 1px solid @primary;
  padding: 6px;
  border-radius: 5px;
}

