@import "../../../../ThemeVariables.less";

// font-size in rems
//  22px = 1.375rem
// 16px = 1rem
// 24pxm= 1.5 rem
// 18pxm= 1.125rem
//14px = 0.875rem
//12px = 0.75rem

.main-tabs {
  // .ant-tabs-nav-wrap {
  //   display: flex;
  //   justify-content: center;
  // }
  .ant-tabs-nav {
    margin: 0;
  }
  .search-type {
    .ant-select-selector {
      border-radius: 30px !important;
      font-size: 1rem;
      height: 38px !important;
      padding: 0px 20px;
    }

    .ant-select {
      width: 100%;
    }

    span.ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
  .ant-tabs-nav-list {
    background: #f2f9fd;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    margin-top: 21px;
    margin-bottom: 21px;
    padding: 5px;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }
  .ant-tabs-tab {
    padding: 5px 0;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: @primary;
    color: #ffffff;
    border-radius: 40px;
    color: #333 !important;

    span {
      color: #ffffff;
    }
  }
  // .ant-tabs-tab:hover {
  //   background: #fff;
  //   border-radius: 40px;
  //   color: #333 !important;
  // }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 5px !important;
    transition: 0.35s;
  }
  .ant-tabs-tab.ant-tabs-tab-active div {
    color: @primary !important;
  }
  .ant-tabs-tab-btn {
    color: #000000;
    font-weight: 600;
    border-radius: 40px;
    padding: 0px 20px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .tabs-icon {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }
  // .ant-tabs-tab:first-child::before {
  //   content: url("../../assets/icons/search-plus.svg");
  //   transform: translate(12px, 3px);
  // }
  // .ant-tabs-tab:nth-child(2)::before {
  //   content: url("../../assets/icons/heart-plus.svg");
  //   transform: translate(12px, 3px);
  // }
  // .ant-tabs-tab:nth-child(3)::before {
  //   content: url("../../assets/icons/procedures.svg");
  //   transform: translate(12px, 3px);
  // }
  // .ant-tabs-tab:nth-child(4)::before {
  //   content: url("../../assets/icons/reward.svg");
  //   transform: translate(12px, 3px);
  // }
  // .ant-tabs-tab:nth-child(5)::before {
  //   content: url("../../assets/icons/faq.svg");
  //   transform: translate(12px, 3px);
  // }
}
.main-search {
  border-radius: 5px;
  padding: 3.5px 11px;
}

.main-search .ant-input-prefix {
  margin-right: 10px;
  margin-left: 5px;
}
.no-search {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h5 {
    color: #161616;
    text-align: center;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 127.273% */
  }
  h6 {
    font-size: 22px;
    text-align: center;
    color: #161616;
    margin-top: 40px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 28px;
  }
  p {
    color: #646464;
  }
}
.no-search-box {
  text-align: center;
}

.no-search span {
  text-align: center;
  font-size: 1.125rem;
  color: #636363;
  width: 58%;
  display: block;
  margin: auto;
  font-weight: 400;
}

.no-search-approval span {
  text-align: center;
  font-size: 16px;
  color: #636363;
  width: 100%;
  display: block;
  margin: auto;
}
.no-search-approval h6 {
  text-align: center;
  font-size: 1.25rem;
  color: #161616;
  margin-top: 30px;
  font-weight: bolder;
}
.main-tabs .ant-card.ant-card-bordered {
  border-radius: 10px;
  min-height: calc(100vh - 70px - 89px - 50px) !important;
  // min-height: 500px;
}
.w-100 {
  width: 100%;
}
.mb-2 {
  margin-bottom: 2rem;
}
.text-center {
  text-align: center;
}
.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 1rem !important;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}

// for search doctor
.search-list {
  h5.ant-typography {
    color: @primary;
    display: flex;
    align-items: center;
    gap: 7px;
    font-weight: 600;
    margin-top: 20px;
  }
  .show-more {
    text-align: center;
    padding: 7px 0;
    .load-more-btn {
      border: 0;
      background: transparent;
      font-size: 1rem;
      font-weight: 700;
      box-shadow: none;
      color: @primary;
    }
  }
  .ant-list {
    // background: @secondary;
    padding: 0;
    border-radius: 8px;
    .ant-list-item {
      padding: 10px 15px;
      border-bottom: 1px solid @list-border !important;
    }
    .ant-avatar {
      width: 30px;
      height: 30px;
    }
  }
  .ant-list-item-meta {
    align-items: baseline;
  }
  .ant-list-item-meta-content {
    display: flex;
    align-items: baseline;
    height: 100%;
    .ant-list-item-meta-description {
      margin-left: 10px;
      margin-bottom: 0;
      color: @dark-gray;
      font-weight: 400;
    }

    h4.ant-list-item-meta-title {
      margin-bottom: 0;
      color: @dark-gray;
      font-weight: 700;
      p {
        margin-bottom: 0;
      }
    }
    li.ant-list-item {
      padding: 5px 0;
    }
  }
}
// .doctors-list {
//   margin-top: 30px;
// }
.d-flex {
  display: flex;
}
.align-baseline {
  text-align: left !important;
  // align-items: baseline !important;
}
.justify-content-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.jutify-content-right {
  justify-content: right !important;
}
.gap-3 {
  gap: 1rem;
}
.gap-4 {
  gap: 2.5rem;
}
.border-0 {
  border: 0;
}
button.theme-btn {
  border: 0;
  font-size: 1rem;
  border-radius: 5px;
  background: @primary;
  color: @white;
  padding: 7px 24px;
  height: auto;
  font-weight: 600;
  &:hover,
  &:focus {
    background: @primary;
    color: @white;
  }
}

button.theme-border-btn {
  border: 1px solid @primary;
  font-size: 1rem;
  border-radius: 5px;
  background-color: transparent;
  color: @primary;
  padding: 6px 23px;
  height: auto;
  font-weight: 600;
  &:hover,
  &:focus {
    background: @primary;
    color: @white;
  }
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.theme-btn[disabled] {
  background-color: #dddddd;
}
.theme-btn[disabled]:hover {
  background-color: #dddddd;
}
.theme-btn[disabled] span {
  color: #161616;
}

.ant-upload-list-item-name {
  color: green;
}

@media screen and (max-width: 576px) {
  .main-tabs {
    .ant-card.ant-card-bordered {
      min-height: calc(100vh - 93px - 67px - 20px) !important;
    }
  }
  main.ant-layout-content > div > .ant-row > .ant-col.ant-col-20 {
    max-width: 100%;
  }
  // .ant-card.ant-card-bordered {
  //   border-radius: 0;
  // }
  .main-tabs.responsive-tab {
    .ant-tabs-nav-list {
      margin-left: unset;
      border-radius: 0 !important;
      box-shadow: none !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding: 7px 10px !important;
      background: transparent !important;
    }
    .ant-tabs-tab {
      padding: 5px !important;
      border-radius: 2px !important;
      flex-grow: 1;
      flex-basis: 0px;
      justify-content: center;
    }
    .ant-tabs-tab-btn {
      color: #7baecf !important;
      padding: 0 6px 0 6px;
      span {
        flex-direction: column;
        align-items: center;
        margin-right: 0 !important;
        gap: 4px;
      }
    }
  }
  .search-list {
    span.anticon.anticon-right {
      display: none;
    }
    .ant-list-item-meta-content {
      .ant-list-item-meta-description,
      h4.ant-list-item-meta-title p {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
  .no-search-box {
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      line-height: 19px;
    }
    p {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
@media screen and (max-width: 400px) {
  .main-tabs {
    .ant-card.ant-card-bordered {
      min-height: calc(100vh - 93px - 67px - 20px) !important;
    }
  }
  .ant-tabs-nav-list {
    margin-left: unset;
    background: transparent !important;
  }
}

