@import "../../../../../ThemeVariables.less";

.doctor-operates {
  padding-bottom: 0;
  .gap-3 {
    img {
      max-width: 220px;
    }
  }
  .mt-3 {
    margin-top: 1.5rem;
  }
  .d-block {
    display: block;
  }
}

.no-margin-b {
  margin-bottom: 0px !Important;
}

