@import "../../../../ThemeVariables.less";

.billing-title {
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 2.25rem;
    font-weight: 700;
    color: @primary;
    margin-bottom: 0px;
  }
  p {
    font-weight: 500;
    font-size: 1.125rem;
    color: #161616;
  }
}

.top-tag {
  margin-top: -24px;
  span {
    border-radius: 0px 0px 12px 12px;
    background: #63c0da;
    width: 160px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
  }
}

.billing-cards {
  h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
  }
  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .ant-divider-horizontal {
    margin: 10px !important;
    border: 0.5px solid #456d87;
  }
  .ant-radio-wrapper span {
    font-size: 1rem;
    font-weight: 600;
  }
  .strike-text {
    color: #bbb;
    font-weight: 600;
    margin-right: 10px;
  }
  .ant-tag {
    border-radius: 100px;
    background: #c1e5fd;
    border: none;
    font-weight: 600;
    height: 21px;
  }
  .pricing {
    font-size: 2.5rem !important;
    font-weight: 700;
    margin-right: 5px;
  }
  .services {
    font-weight: 500;
    font-size: 0.875rem;
    img {
      margin-right: 10px;
      height: 14px;
    }
  }
  .members {
    // height: 36px;
    padding: 16px;
  }

  .emp-radio {
    .ant-radio-wrapper {
      align-items: center;

      .ant-radio-inner {
        height: 27px;
        width: 27px;
      }
    }
  }
}

.manage-billing-cards {
  span {
    color: #fff;
    font-weight: 500;
  }
  h5 {
    font-size: 1.5rem !important;
    font-weight: 600;
    margin-bottom: 0px;
    color: #fff;
  }
  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0px;
    color: #fff;
  }

  .strike-text {
    color: #bbb;
    font-weight: 600;
    margin-right: 10px;
  }
  .ant-tag {
    border-radius: 100px;
    background: #c1e5fd;
    border: none;
    font-weight: 600;
    height: 21px;
    color: #000;
  }
  .pricing {
    font-size: 2.5rem;
    color: #63C0DA;
    font-weight: 700;
    margin-right: 5px;
  }
  .services {
    font-weight: 500;
    font-size: 0.875rem;
    img {
      margin-right: 10px;
    }
  }
  .plan-btn {
    width: 200px;
    background-color: #fff;
    span {
      color: #000;
    }
  }
  .plan-btn:hover,
  .plan-btn:active,
  .plan-btn:focus {
    background-color: #fff;
    span {
      color: #000;
    }
  }
}

.card-border {
  border: 1px solid #000;
}

.hover-pricing {
  border: 1px solid #fff;
  transform: scale(1.07);
  background: @primary;
  color: #fff;
  h4 {
    color: #fff;
  }
  h5 {
    color: #fff;
  }
  .ant-radio-wrapper span {
    color: #fff;
  }
  .theme-btn {
    background-color: #fff;
    span {
      color: #000;
    }
  }
}

.bottom-box {
  background: @primary;
  height: 35vh;
  margin: -200px -24px -24px -24px;
  border-radius: 10px;
}

.manage-bottom-box {
  background: @primary;
  height: 17px;
  margin: 0px -24px -24px -24px;
  border-radius: 10px;
}

.top-box {
  background: @primary;
  height: 15px;
  margin-top: 20px;
  margin-bottom: -10px;
  border-radius: 10px;
}

.managebill-card {
  padding: 30px;
  background: @primary;
  margin: -24px -24px 0px -24px;
  border-radius: 10px;
  height: 60vh;

  .plan-details {
    padding-left: 10px;
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
  }
  p {
    font-weight: 500;
    font-size: 1.125rem;
    color: #fff;
  }
}

.billing-features {
  font-weight: 500;
  font-size: 0.875rem;

  .ant-divider-horizontal {
    margin: 10px !important;
    border: 0.5px solid #456d87;
  }

  img {
    margin-right: 10px;
    height: 21px;
  }
  p {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 550;
  }

  .subscription-date {
    font-size: 1.125rem;
    display: flex;
    justify-content: center;

    p {
      margin-bottom: 0px;
    }

    .date {
      font-size: 1.5rem;
      color: @primary;
      font-weight: 700;
    }
  }
}

.billing-features-emp {
  font-weight: 500;
  font-size: 0.875rem;

  .ant-divider-horizontal {
    margin: 10px !important;
    border: 0.5px solid #456d87;
  }

  img {
    margin-right: 10px;
    height: 21px;
  }
  p {
    font-size: 0.875rem;
    font-weight: 500;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 550;
  }
}

.selected-plan {
  color: #63c0da !important;
}

.reward-success-modal .ant-modal-body h6 {
  margin-top: 0px;
  font-size: 1.5rem !important;
}

.not-sure-modal .ant-modal-body {
  padding: 30px !important;
}

.cancel-reason {
  span {
    color: #646464;
    font-size: 14px;
    font-weight: 500;
  }
  img {
    height: 18px;
    margin-right: 10px;
  }
  p {
    margin: 10px 0px 0px 0px !important;
    font-size: 1rem !important;
  }
}

.emp-plans {
  margin-top: -140px;
}

// .checkout-btn {
//   background-color: #fff !important;
//   span {
//     color: #000 !important;
//   }
// }
.checkout-btn:hover,
.checkout-btn:active,
.checkout-btn:focus {
  background-color: #fff !important;
  span {
    color: #000 !important;
  }
}

.checkout-btn[disabled]:hover,
.checkout-btn[disabled]:active,
.checkout-btn[disabled]:focus {
  background-color: #dddddd !important;
  span {
    color: #000 !important;
  }
}

.plan-name {
  font-weight: 600;
}

