@import "../../../../ThemeVariables.less";

.global-search-wrapper {
  padding: 0px 50px 0px 50px;
  h5 {
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 28px;
  }
  p {
    color: #000;
    font-weight: 800;
    font-size: 0.875rem;
    margin-bottom: 5px;
  }
  // span {
  //   color: #646464;
  //   font-size:18px
  // }
}

.no-result {
  h5 {
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 28px;
    color: #161616;
  }
  span {
    color: #646464;
    font-size: 1rem;
  }
  img {
    max-width: 100%;
  }
}

.search-head {
  display: block;
}

.global-searchbar {
  .search-ic {
    border: none !important;
  }
  .ant-input-prefix {
    border: none !important;
  }
  width: 345px;
  span {
    border-radius: 30px;
    border: 1px solid #dcdcdc;
    background: #F0F3F4;
  }
  .ant-input {
    background: #F0F3F4 !important;
    border-radius: 0px !important;
  }
  margin-left: 20px;
}

.search-suggestions {
  cursor: pointer;
  span {
    line-height: 25px;
    border-radius: 50px;
    background-color: #dcf1fd;
    color: #000;
    margin-top: 10px;
    font-weight: 500;
    border: none;
  }
}

.search-heading {
  // background-color: @primary;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  h5 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    // padding: 8px;
    color: #fff !important;
  }
  .search-result {
    color: #b2c7e4 !important;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-weight: 600;
  }
}

.search-banner {
  color: #646464;

  p {
    font-weight: 500;
    color: #646464;
  }
  span {
    font-weight: 700;
  }
}
.search-collaps {
  padding-left: 40px;
  padding-right: 40px;
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-header {
    background-color: @primary !important;
    border-radius: 5px !important;
  }
  .ant-collapse-expand-icon {
    color: #fff !important;
  }

  .ant-list-item-meta-content {
    justify-content: space-between !important;
  }

  .ant-list-empty-text {
    padding: 5px !important;
  }

  .ant-list {
    border: 1px solid #ebe7e7;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}

.search-result-p {
  width: 300px;
  justify-content: right;

  img {
    margin-right: 3px;
  }
  p {
    font-weight: 500;
    font-size: 14px !important;
    margin-bottom: 0px !important;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
}

.show-more {
  background: @secondary;
}

.search-back {
  span {
    font-weight: 500;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  font-weight: 500;
}

.searchbar-input {
  height: 40px;
  border: 1px solid #F0F3F4;
  background: #F0F3F4;
}

.rounded-card {
  border-radius: 10px;
}
