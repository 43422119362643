@import "../../.././../.././ThemeVariables.less";

// body {
//   background-color: #ffffff !important;
// }

.flex-end {
  align-items: flex-end !important;
}

.flex-start {
  align-items: flex-start !important;
}

.onboard-wrapper {
  height: 100vh;
  background-color: #fff;

  .onboarding-page {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;

    .bazar-logo {
      width: 148px;
    }

    h5 {
      color: #161616;
      text-align: center;
      font-size: 22px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 127.273% */
    }
    h6 {
      font-size: 1.375rem;
      text-align: center;
      color: #161616;
      margin-top: 40px;
      font-weight: 600;
      // margin-bottom: 0;
      // line-height: 28px;
    }
    p {
      font-size: 1.125rem;
      color: #646464;
      font-weight: 500;
      padding: 0px 5px 0px 5px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.onboard-modal {
  img {
    margin-right: 30px;
    max-width: 100%;
  }
  h6 {
    align-items: baseline;
    font-size: 1.5rem;
    text-align: center;
    color: @primary;
    font-weight: 800;
    margin-bottom: 0px !important;
  }
  span {
    font-size: 1.125rem;
    color: #646464;
    font-weight: 500;
    text-align: left;
  }
}

.register-text {
  white-space: pre-line; /* Preserve line breaks and collapse multiple spaces */
}

.onboard-btn {
  margin-top: 10px;
  width: 230px;
  height: 46px;
  border: 0;
  font-size: 1rem;
  border-radius: 5px;
  background: @primary;
  color: @white;
  padding: 7px 24px;
  height: auto;
  font-weight: 500;
  &:hover,
  &:focus {
    background: @dark-gray;
    color: @white;
  }
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.no-search-img {
  margin-top: 23px !important;
}

.separator {
  padding-left: 55px;
  padding-right: 55px;
  display: flex;
  color: @primary !important;
  justify-content: center;
  align-items: center;
  // height: 100%;
  font-weight: bold;
  font-size: 30px;
  flex-direction: column;
}

.separator-on {
  padding-left: 63px;
  padding-right: 50px;
  display: flex;
  color: #646464 !important;
  justify-content: center;
  align-items: center;
  // height: 100%;
  font-weight: bold;
  font-size: 18px;
  flex-direction: column;
}

.onboard-form-1 {
  background-color: #fafafa;
  color: #000;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid @primary;
}

.onboard-form-2 {
  background-color: #fafafa;
  color: #000;
  border-radius: 10px;
  border: 1px solid @primary;
  padding: 20px;
}

.onboard-form-2:active {
  background-color: @primary !important;
}

// .onboard-form-1 label {
//   color: #fff !important;
// }

.onboard-form-1.active-column,
.onboard-form-1.active-column label {
  background-color: @primary;
  color: #fff;
}

.onboard-form-2.active-column,
.onboard-form-2.active-column label {
  background-color: @primary;
  color: #fff;
}

.ant-tooltip-inner {
  width: 355px !important;
  border-radius: 6px;
}

.employer-warning {
  .employer-warning-text {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0px;
    // margin-left: 15px;
    color: @primary;
  }
  span {
    font-weight: 500;
    font-size: 14px;
  }

  .employer-warning-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-left: 15px;
  }
}

.employer-contact-text {
  background-color: #f2f9fd;
  padding: 15px;
  border-radius: 6px;
  color: @primary;
  font-size: 14px;

  // padding-left: 3px;
  span {
    padding-top: 5px !important;
    font-weight: 600;
  }
}

.centered-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px; /* Add some spacing between columns */
  justify-content: center;
}

.right-col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.left-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.separator-on {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hover-onboarding {
  margin-bottom: 0px !important;
}
.hover-onboarding:hover {
box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
}

