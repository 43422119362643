@import "../../../../ThemeVariables.less";

// .user-img {
//   margin-top: 0% !important;
//   margin-right: 0px !important;
//   width: 100% !important;
//   height: 100% !important;
// }
.user-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -10px;
}

.userprofile-tab .ant-tabs-nav {
  width: 60%;
  margin: auto;
  .ant-tabs-tab {
    padding: 6px 0;
  }
}
.procedures {
  .ant-table-wrapper {
    margin-top: 30px;
  }
  .ant-table-thead > tr > th {
    background: @secondary;
    color: @dark-gray;
    font-size: 1rem;
    font-weight: 600;
    border-right: 0 !important;
  }
  .ant-table-tbody > tr > td {
    color: @dark-gray;
    font-size: 1rem;
    color: @dark-gray;
    font-weight: 500;
    border-right: 0 !important;
    vertical-align: top;
    a {
      color: @primary;
      // border-bottom: 1px solid;
      font-weight: 600;
    }
    &:last-child {
      color: @green;
    }
  }
  table {
    border-right: 1px solid #f0f0f0;
  }
}
.headline {
  background-color: #f2f9fd;
  // height: 81px;
  width: 100%;
  .sub-title {
    h3 {
      color: @black;
    }
    span {
      font-weight: 500;
    }
  }
}
.headline .sub-head {
  padding: 10px;
}
.headline p {
  font-family: "Roboto";

  padding-left: 10px;
}
.theme-btn span {
  color: #fff;
}
.left-text {
  justify-content: right;
}
.contact-card {
  border: 0.5px solid #000;
  border-radius: 6px !important;
}
.profile-card {
  border: none !important;
  margin: 18px 0 0px;
  .ant-card-body {
    .ant-card-grid:nth-child(1) {
      border-top-left-radius: 6px;
    }
    .ant-card-grid:nth-child(2) {
      border-top-right-radius: 6px;
    }
    .ant-card-grid:last-child {
      border-bottom-right-radius: 6px;
    }
    .ant-card-grid:nth-last-child(2) {
      border-bottom-left-radius: 6px;
    }
  }
  .ant-card-grid {
    padding: 20px 24px;
    .ant-row {
      div {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.employer-profile-card {
  border: none !important;
  .ant-card-body {
    .ant-card-grid:nth-child(1) {
      border-top-left-radius: 6px;
    }
    .ant-card-grid:nth-child(2) {
      border-top-right-radius: 6px;
    }
    .ant-card-grid:last-child {
      border-bottom-right-radius: 6px;
    }
    .ant-card-grid:nth-last-child(2) {
      border-bottom-left-radius: 6px;
    }
  }
}

.border {
  border: 1px solid #000;
  // padding: 10px;
}
.profile-bold {
  font-weight: bold;
}
.profile-details {
  color: #646464 !important;
  font-weight: 450 !important;
}

.border-right {
  border-right: 1px solid #000;
}
@media screen and (max-width: 576px) {
  .procedures {
    .ant-table-content {
      overflow-x: scroll;
      width: 100%;
      &::-webkit-scrollbar {
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.phone-icon {
  transform: rotateZ(93deg);
}

.request-appointment {
  h6 {
    font-weight: 700;
    font-size: 1.5rem !important;
    color: @primary;
    margin-bottom: 0;
  }
  .ant-picker {
    width: 100%;
    border: 0;
    padding: 0;
    span.ant-picker-clear {
      top: 19px;
      right: 11px;
      font-size: 1rem;
    }
  }
  .ant-picker-focused {
    border: 0;
    box-shadow: none;
  }
  span.ant-picker-suffix {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: large;
  }
  .ant-input {
    padding: 4px 11px;
  }
  textarea.ant-input {
    border: 1px solid #adadad;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  .ant-checkbox-input {
    top: -2px;
  }
  label.ant-checkbox-wrapper span {
    font: 1rem !important;
  }
  .fs-1 {
    font-size: 1.125rem;
    color: @gray;
  }
  span.default-msg {
    background: @secondary;
    padding: 10px;
    margin: 15px 0 0 0;
    display: inline-block;
    font-size: 1rem;
    border: 1px dashed @gray;
    border-radius: 5px;
  }
}

.upload-section {
  margin-top: -30px !important;
  .ant-image,
  .relative {
    position: relative;
  }
  .upload-profile {
    background: #fff;
    border-radius: 100px;
    position: relative;
  }
  .ant-image-mask {
    border-radius: 100px;
  }
  .upload-profile::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 120px;
    background-color: white;
    top: 0;
    left: 0;
    transform: scale(1.5);
  }
  .upload-btn {
    position: absolute;
    bottom: 0;
    right: 5px;
    color: @primary;
    border-color: @primary;
    svg {
      stroke: @primary;
      stroke-width: 30px;
    }
    &:hover {
      color: @primary;
      border-color: @primary;
    }
  }
}

.upload-services-photo {
  .ant-image,
  .relative {
    position: relative;
  }
  .upload-profile {
    background: #fff;
    border-radius: 100px;
    position: relative;
  }
  .ant-image-mask {
    border-radius: 100px;
  }
  .upload-profile::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 120px;
    background-color: white;
    top: 0;
    left: 0;
    transform: scale(1.5);
  }
  .upload-btn {
    position: absolute;
    bottom: 0;
    right: 5px;
    color: @primary;
    border-color: @primary;
    svg {
      stroke: @primary;
      stroke-width: 30px;
    }
    &:hover {
      color: @primary;
      border-color: @primary;
    }
  }
}
.mt-2 {
  margin-top: 1rem;
}

.contact-card {
  border: 0.5px solid @gray;
}

.ant-card-grid-hoverable:hover {
  position: unset;
  z-index: unset;
  box-shadow: unset;
  border: 0.5px solid;
}

.no-border {
  border-left: none !important;
  border-bottom: none !important;
}
.no-border-top {
  border-left: none !important;
}
.no-border-bottom {
  border-bottom: none !important;
}

.disable-request:hover {
  color: @primary !important;
  background-color: #f5f5f5 !important;
}

.border-table {
  border: 0.5px solid #646464;
}

.border-left {
  border-left: none !important;
}

.hardcoded-row {
  background: red !important;
  background-color: yellow !important; /* Set the desired background color */
  /* Add any other styling properties */
}

.dep-table {
  .ant-table-content {
    border-radius: 10px;
    // border: 1px solid red;
    border: 1px solid #bebdbd;
  }
  tbody {
    background: #f4f4f4;
  }
  table tr:first-child {
    background-color: #fff; /* Set the desired background color */
    /* Add any other styling properties */
  }
  table tr {
    margin-left: 100px !important; /* Set the desired background color */
    padding-left: 100px !important; /* Set the desired background color */
    margin-top: 100px !important; /* Set the desired background color */
    /* Add any other styling properties */
  }
}

.mobile-procedure {
  .amount {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12ch;
  }
}

/* YourComponent.css */

.bordered-column {
  border: 1px solid @primary;
  padding: 15px;
  margin: -1px -1px 0 0;
  box-sizing: border-box;
  font-size: 1rem !important;
}

.mobile-employer {
  border: 1px solid @primary;
  border-radius: 6px;
  padding: 15px;

  .ant-divider-horizontal {
    margin: 10px;
  }
}

.top-left-border {
  border-top-left-radius: 6px;
}
.top-right-border {
  border-top-right-radius: 6px;
}
.bottom-right-border {
  border-bottom-right-radius: 6px;
}
.bottom-left-border {
  border-bottom-left-radius: 6px;
}

// .sub-header-font {
//   /* Your styles for sub-header-font */
// }

.benefit-call {
  color: #fff !important;
  svg {
    color: #fff !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 780px) {
  .profile-card {
    .ant-card-body {
      padding: 0px !important;
    }
  }
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:hover {
  border-color: #ff4d4f !important;
}

