@import "../../../../ThemeVariables.less";

.sub-header {
  h3.ant-typography {
    margin-bottom: 0;
    font-size: 1.2rem;
    color: @primary;
    font-weight: 700;
  }
  margin-top: 15px;
  align-items: center;
  span {
    font-size: 1rem;
    color: @gray;
  }
  button.ant-btn.ant-btn-default.ant-btn-icon-only {
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    width: 30px;
    height: 30px;
  }
  .sub-title-box {
    align-items: center;
    gap: 15px;
  }

  .sub-title-box-surgery {
    align-items: center;
    gap: 11px;
    font-size: 18px;
    font-weight: 600;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: 1px solid #adadad;
    border-radius: 6px;
  }
  .ant-select {
    min-width: 250px;
    span.ant-select-selection-item {
      color: @dark-gray;
      font-size: 1.1rem;
    }
    span.ant-select-arrow {
      top: 16px;
    }
  }
}
nav.ant-breadcrumb {
  align-items: center;
  display: flex;
  margin: 20px 0 0 0;
}
span.ant-breadcrumb-link {
  font-weight: 700;
  font-size: 1.125rem;
  color: @primary;
}

span.ant-breadcrumb-link a {
  font-weight: 500;
  color: @dark-gray;
}

.list-content {
  margin-top: 12px;
  border-radius: 5px !important;
  margin-bottom: 20px;
  min-height: calc(100vh - 70px - 75px - 50px);
  .main-search {
    height: 34px;
    &:focus {
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }
  .nxt-prv-btn {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 14px;
    }
  }
  .single-list-card {
    text-align: center;
    padding: 30px;
    background: @secondary;
    // margin-top: 20px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    h5 {
      font-size: 1.25rem;
      margin-top: 14px;
      font-weight: 700;
      margin-bottom: 0;
      color: @dark-gray;
    }
    .list-avatar {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 100px;
      margin-top: 10px;
    }
    p.doctor-associates {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 5px;
      color: @dark-gray;
    }
    p.single-address {
      color: @mid-gray;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 7px;
      margin-top: -10px;
    }
    .appointment-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      border: 0;
      background: transparent;
      &:hover {
        transform: scale(1.2);
      }
    }
    .rat-box {
      p {
        font-size: 1rem;
        color: @dark-gray;
        // margin-right: 10px;
        font-weight: 600;
        img {
          margin-bottom: 6px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
.appointment-modal {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-footer {
    border: 0;
    text-align: left;
    padding: 0 0 35px 24px;
  }
}

@media screen and (max-width: 576px) {
  main.ant-layout-content > div > .ant-row.ant-row-center {
    display: block !important;
    width: 100% !important;
  }
}

li.rc-pagination-item.rc-pagination-item {
  display: none !important;
  text-decoration: none !important;
}

ul.rc-pagination {
  list-style: none !important;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

li.rc-pagination-jump-prev {
  display: block !important;
}

ul.rc-pagination li {
  padding: 0 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.no-data {
  min-height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.form-bottom {
  margin-bottom: 0px !important;
}

.ant-select-tree-title {
  font-size: 18px !important;
  color: @primary !important;
  font-weight: 500;
}

.ant-select-tree-title .child-option {
  font-size: 14px !important;
}

// .ant-select-tree-switcher-noop {
//   display: none !important;
// }

.ant-select-tree .ant-select-tree-treenode {
  padding: 10px 0 5px 0px !important;
}

.doc-dropdown {
  .ant-select-selector {
    span {
      color: @primary !important;
      font-weight: 500;
    }
    font-weight: 500;
    color: @primary !important;
    border: 1px Solid @primary !important;
    border-radius: 10px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .ant-input {
    border: 1px Solid @primary !important;
    border-radius: 10px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.site-tag-plus {
  .ant-dropdown.ant-dropdown-placement-bottomLeft {
    margin-top: 10px !important;
  }
  // span.ant-tag.site-tag-plus {
  //   padding: 10px !important;
  // }
  // background: #fff;
  // border-style: dashed;
  border-radius: 12px;
  background: @primary;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  padding: 2px 5px 2px 5px;
}

.tag-input {
  width: 200px;
  margin-right: 8px;
  vertical-align: top;
}

.ant-dropdown.doc-body-part.ant-dropdown-placement-bottomLeft {
  .ant-dropdown-menu {
    margin-top: 8px !important;
    border: 1px solid @primary;
    border-radius: 6px !important;

    span {
      font-weight: 500;
    }
  }
}
.speciality-selectbar {
  width: 200px;
}
.doc-dropdown-bodyPart {
  width: calc(100% - 200px);
  border: 1px solid @primary !important;
  overflow-x: auto !important;
  height: 32px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  display: inline-flex !important;
  &::-webkit-scrollbar {
    display: none;
  }
  .doc-dropdown-bodyPart-child {
    display: flex !important;
    align-items: center !important;
    margin-left: 10px !important;
    max-height: 42px !important;
  }
  .edit-tag {
    user-select: none;
    background-color: #dff1fc;
    border-color: #dff1fc;
    font-weight: 600;
    color: black;
    border-radius: 12px;
  }
}

.desired-modal {
  .ant-modal-content {
    border-radius: 10px;
  }

  span.anticon.anticon-close.ant-modal-close-icon {
    background: @primary;
    padding: 4px;
    border-radius: 20px;
    color: #ffffff;
  }
}

.need-assistance-btn {
  margin-top: 25px;
  border-radius: 40px;
  background: rgb(231, 165, 0);
  padding: -7px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

@media screen and (min-width: 320px) and (max-width: 780px) {
  .doc-dropdown .ant-select-selector {
    border-radius: 6px 6px 0 0 !important;
  }
  .doc-dropdown-bodyPart {
    border-radius: 0 0 6px 6px !important;
  }
}

