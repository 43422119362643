@import "../../../../../ThemeVariables.less";

.doctor-overview {

  .doc-tag-over {
    margin-left: 10px;
  }
  .specialty-tags {
    margin-left: 7px;
    font-weight: 700;
    margin-top: 10px;
    font-size: 14px;
    border-radius: 50px;
    color: #e7a500 !important;
    border: 1px solid #e7a500;
    display: inline-flex;
    align-items: center;
    background: #FFF8E8;
  }
  .overview-details b{
    font-size: 16px;
    color: @dark-gray;
  }
  a {
    font-size: 1.125rem;
    color: @primary;
    font-weight: 700;
    margin-top: 15px;
    img {
      margin-bottom: 6px;
    }
  }
  b {
    font-size: 1.125rem;
    color: @dark-gray;
  }
  p {
    font-size: 1rem;
    color: @dark-gray;
    margin-bottom: 12px;
  }
}

