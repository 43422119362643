@import "../../../../../ThemeVariables.less";
.services {
  .service-box {
    text-align: center;
    background: #f2f9fd;
    padding: 45px 0;
    margin: 15px;
    transition: 0.3s;
    border-radius: 10px;
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      box-shadow: inset 0 0 9px 0px rgb(100 152 156 / 14%);
      h5 {
        color: @primary !important;
      }
    }
    h5.ant-typography {
      margin-top: 18px;
      color: @dark-gray;
      transition: 0.3s;
    }
  }
  .ant-card.ant-card-bordered.mb-2 {
    min-height: 72vh;
    padding-bottom: 30px;
  }
}
p.suggest-btn {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0;
  button {
    border: 0;
    background: transparent;
    color: @primary;
    box-shadow: none;
    text-shadow: none;
    font-size: 1.125rem;
    font-weight: 500;
    border-bottom: 1px solid;
    padding: 0;
    margin: 0 5px;
    height: auto;
    &:hover {
      background: transparent !important;
      color: @primary !important;
      border-bottom: 1px solid @primary;
      transform: scale(1.05);
    }
    &:focus {
      border: 0;
      background: transparent;
      color: @primary;
      box-shadow: none;
      text-shadow: none;
      font-size: 1.125rem;
      font-weight: 500;
      border-bottom: 1px solid;
      padding: 0;
      margin: 0 5px;
    }
  }
}
.suggest-modal {
  .ant-modal-content {
    border-radius: 11px;
    overflow: hidden;
    padding: 30px;
    .ant-modal-header {
      text-align: center;
      border-bottom: 0;
      .ant-modal-title {
        font-size: 1.5rem;
        font-weight: 700;
        color: @primary;
      }
    }

    .ant-modal-body {
      padding-bottom: 10px;
      padding-top: 10px;
    }
    label {
      font-size: 1rem;
      color: Gray;
      font-weight: 400;
    }
    input,
    textarea.ant-input {
      border: 1px solid #adadad;
      padding: 4px 11px;
      border-radius: 6px;
      font-size: 1rem;
      // margin-bottom: 15px;
      &::placeholder {
        font-size: 1rem !important;
      }
    }
  }
  top: 200px;
  span.anticon.anticon-close.ant-modal-close-icon {
    background: @primary;
    padding: 4px;
    border-radius: 20px;
    color: @white;
  }
  .ant-modal-footer {
    text-align: center;
    border: 0;
    padding-top: 0;
    button.ant-btn.ant-btn-primary {
      font-size: 1rem;
      background-color: @primary;
      color: @white;
      border-radius: 6px;
      border-color: @primary;
      height: 40px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 576px) {
  .services {
    .service-box {
      width: 100%;
      margin: 0 0 10px;
      padding: 20px 0;
      h5.ant-typography {
        margin-top: 0.5rem !important;
      }
      img {
        height: 45px;
      }
    }
    p.suggest-btn {
      position: static;
      margin-top: 30px;
    }
  }
  .suggest-modal {
    .ant-modal-body {
      padding: 0px !important;
    }
    // min-width: 100%;
    // position: fixed;
    // left: 0;
    // bottom: 0 !important;
    // top: auto !important;
    // margin-bottom: 0;

    // button.ant-modal-close {
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   text-align: center;
    //   display: flex;
    //   justify-content: center;
    //   top: -55px;
    //   span.anticon.anticon-close.ant-modal-close-icon {
    //     background: transparent !important;
    //     svg {
    //       transform: scale(1.75);
    //     }
    //   }
    // }

    // .ant-modal-content {
    //   border-radius: 20px 20px 0 0 !important;
    //   padding: 30px 0 0 0;
    //   overflow: inherit;
    // }
    // .ant-modal-footer {
    //   margin-top: 5px !important;
    //   background: @secondary;
    //   padding: 20px 16px;
    //   .ant-form-item {
    //     margin-bottom: 0;
    //   }
    //   button.ant-btn.ant-btn-primary {
    //     width: 100% !important;
    //   }
    // }
  }
}

.body-area {
  display: flex;
  flex-wrap: wrap;
  img {
    margin-bottom: 5px;
  }
  .service-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 230px;
    height: 190px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    transition: 0.3s;

    h5.ant-typography {
      margin: 0;
      margin-bottom: 0px;
      font-size: 1.25rem;
      color: @dark-gray;
      text-align: center;
    }
    p {
      margin-bottom: 5px;
      width: 202px;
      color: #676767;
      font-size: 14px;
      text-align: center;
    }
  }

  .service-box:hover {
    cursor: pointer;
  }

  .ant-card.ant-card-bordered.mb-2 {
    min-height: 72vh;
    padding-bottom: 30px;
  }
}

.body-area-surgery {
  display: flex;
  flex-wrap: wrap;
  img {
    margin-bottom: 3px;
  }
  .service-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 230px;
    height: 165px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    transition: 0.3s;

    h5.ant-typography {
      margin: 0;
      margin-bottom: 0px;
      color: @dark-gray;
      text-align: center;
    }
    p {
      margin-bottom: 3px;
      width: 202px;
      color: #676767;
      text-align: center;
    }
  }

  .service-box:hover {
    cursor: pointer;
  }

  .ant-card.ant-card-bordered.mb-2 {
    min-height: 72vh;
    padding-bottom: 30px;
  }
}

.body-area-ic {
  display: flex;
  flex-wrap: wrap;
  img {
    margin-bottom: 3px;
  }
  .service-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 230px;
    height: 120px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    transition: 0.3s;

    h5.ant-typography {
      margin: 0;
      margin-bottom: 3px;
      font-size: 1.25rem;
      color: @dark-gray;
      text-align: center;
    }
    p {
      margin-bottom: 3px;
      width: 202px;
      color: #676767;
      font-size: 14px;
      text-align: center;
    }
  }

  .service-box:hover {
    cursor: pointer;
  }

  .ant-card.ant-card-bordered.mb-2 {
    min-height: 72vh;
    padding-bottom: 30px;
  }
}

.bodyarea-search {
  // border: none;
  // border-bottom: 1px solid #adadad;
  border-radius: 5px;
  outline: none;
  padding: 3.5px 11px !important;
  &:hover,
  &:focus {
    border-color: #adadad !important;
    box-shadow: none !important;
  }

  input:hover {
    box-shadow: none !important;
    border-color: none !important;
  }
}

.desired-modal {
  .ant-modal-content {
    border-radius: 10px;
  }

  span.anticon.anticon-close.ant-modal-close-icon {
    background: @primary;
    padding: 4px;
    border-radius: 20px;
    color: #ffffff;
  }
}

.not-sure-modal {
  .ant-card-body {
    padding: 15px !important;
  }
  .ant-modal-body {
    padding: 50px 40px 50px 40px !important;
  }
  p {
    margin-top: 0px;
    font-weight: 500;
    color: @primary !important;
    line-height: 12px;
    // margin-bottom: 25px;
  }
  .doctor-profile {
    p {
      margin-top: 15px;
      font-weight: 600;
    }
  }
}
.service-box.active-column {
  background-color: #f2f9fd;
  border: 1px solid @primary;
}

.body-area-surgery,
.body-area-ic,
.body-area {
  max-height: calc(3 * 135px); /* Set the maximum height of the div */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Customize the scrollbar for both classes */
.body-area-surgery::-webkit-scrollbar,
.body-area-ic::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
}

.body-area-surgery::-webkit-scrollbar-thumb,
.body-area-ic::-webkit-scrollbar-thumb {
  background-color: #c2c2c2; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}

.body-area-surgery::-webkit-scrollbar-track,
.body-area-ic::-webkit-scrollbar-track {
  background-color: #f2f2f2; /* Set the color of the scrollbar track */
}

