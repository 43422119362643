@import "../../../../ThemeVariables.less";

img.doctor-img {
  width: 657px;
  height: 385px;
  object-fit: cover;
  // border-radius: 150px;
  border: 3px solid @list-border;
}

img.doctor-img-1 {
  width: 439px;
  height: 385px;
  object-fit: cover;
  // border-radius: 150px;
  border: 3px solid @list-border;
}

img.image-bottom {
  width: 356px;
  height: 385px;
  object-fit: cover;
  padding: 50px;
  // border-radius: 150px;
}

.ant-ribbon-text {
  color: black !important;
  font-weight: bold !important;
  font-size: 12px !important;
  margin-top: 5px !important;
}

