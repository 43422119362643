@import "../../../../../ThemeVariables.less";

.reward-header {
  text-align: center;
  h5.ant-typography {
    font-weight: 700;
    margin-top: 15px;
    line-height: 18px;
    color: @primary;
  }
  span {
    font-weight: 400;
    font-size: 1.125rem;
    color: @gray;
  }
}
.reward-input-box {
  margin-top: 30px;
  .ant-form-item-label {
    padding-bottom: 0;
  }
  label {
    font-weight: 400;
    font-size: 1.125rem;
    color: @gray;
  }
  input {
    // border: 1px solid #adadad;
    padding: 4px 11px;
    border-radius: 6px;
    // margin-bottom: 15px;
  }
  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
    span.ant-upload {
      button {
        width: 100%;
        height: 150px;
        border-radius: 6px;
        border: 1px solid #adadad;
        background-color: @secondary;
        font-size: 1rem;
        color: @dark-gray;
        img.UploadIcn {
          width: 37px;
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
    }
  }
  .mt-3 {
    margin-top: 1.25rem;
  }
  .mt-1 {
    margin-top: 0.6rem;
  }
  button.ant-btn.ant-btn-primary.mt-3 {
    background-color: @primary;
    color: #ffffff;
    border-radius: 6px;
    border-color: @primary;
    height: 40px;
    font-weight: 500;
    font-size: 1rem;
  }
  .form-control {
    width: 100%;
    // margin-bottom: 15px;
    .ant-select-selector {
      border: 1px solid #adadad !important;
      border-radius: 6px;
      padding: 4px 11px;
    }
    span.ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}

// .__floater__arrow{
//   bottom: 6px !important;
//   margin-left: 80px;
// }
span.ant-ribbon-text {
  color: #ffffff !important;
}

.react-joyride__tooltip {
  border-radius: 10px !important;
  border: 1px solid white;
}

.reward-success-modal {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    text-align: center;
    text-align: center;
    padding: 80px 20px;

    h6 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 20px;
      color: @primary;
    }

    p {
      font-size: 1.125rem;
      color: @dark-gray;
    }

    button.ant-btn.ant-btn-default.theme-btn {
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
  .ant-modal-footer {
    display: none;
  }
  span.anticon.anticon-close.ant-modal-close-icon {
    background: @primary;
    padding: 4px;
    border-radius: 20px;
    color: #ffffff;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
}

@media screen and (max-width: 576px) {
  .reward-success-modal {
    min-width: 100%;
    // position: fixed;
    // left: 0;
    // margin: 0px 10px 0px 10px;
    // bottom: 0 !important;
    // top: auto !important;
    .footer-btn {
      padding: 20px;
      background: @secondary;
      // width: calc(100% + 40px);
      // margin-left: -20px;
      button.ant-btn.ant-btn-default.theme-btn {
        width: 100%;
        font-size: 1.125rem;
        font-weight: 500;
      }
    }
    margin-bottom: 0;
    // .ant-modal-body {
    //   padding-bottom: 0;
    // }
    // button.ant-modal-close {
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   text-align: center;
    //   display: flex;
    //   justify-content: center;
    //   top: -55px;
    //   span.anticon.anticon-close.ant-modal-close-icon {
    //     background: transparent !important;
    //     svg {
    //       transform: scale(1.75);
    //     }
    //   }
    // }
    .ant-modal-content {
      // border-radius: 20px 20px 0 0 !important;
      padding: 30px 0 0 0;
      overflow: inherit;
      margin-right: 15px;
      margin-left: 15px;
    }
    .ant-modal-footer {
      margin-top: 5px !important;
      background: @secondary;
      padding: 10px 24px !important;
      button.ant-btn.ant-btn-primary {
        width: 100% !important;
      }
    }
  }
}

.reward-form {
  margin-bottom: 10px !important;
  padding-bottom: 0px !important;
  row-gap: 0px !important;
}

@media screen and (min-width: 320px) and (max-width: 780px) {
  .r-card {
    .ant-card-body {
      padding: 24px !important;
    }
  }
  
}

