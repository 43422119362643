//deafult screen
:root {
  ---header-font: 18px;
  ---sub-header-font: 16px;
  ---p-font: 14px;
  ---button-font: 16px;
  ---table-font: 14px;
  ---tag-font: 13px;
  ---select-font: 14px;
}

// mobile screen
@media screen and (min-width: 300px) and (max-width: 780px) {
  :root {
    ---header-font: 15px;
    ---sub-header-font: 14px;
    ---p-font: 13px;
    ---button-font: 12px;
    ---table-font: 12px;
    ---tag-font: 10px;
    ---select-font: 12px;
  }
}

// tablet screen
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  :root {
    ---header-font: 18px;
    ---sub-header-font: 16px;
    ---p-font: 14px;
    ---button-font: 14px;
    ---table-font: 12px;
    ---tag-font: 13px;
    ---select-font: 14px;
  }
}

// large screen
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  :root {
    ---header-font: 23px;
    ---sub-header-font: 19px;
    ---p-font: 18px;
    ---button-font: 18px;
    ---table-font: 17px;
    ---tag-font: 16px;
    ---select-font: 16px;
  }
}

.header-font {
  font-size: var(---header-font) !important;
}
.sub-header-font {
  font-size: var(---sub-header-font) !important;
}
.p-font {
  font-size: var(---p-font) !important;
}
.button-font {
  font-size: var(---button-font) !important;
}
.table-font {
  font-size: var(---table-font) !important;
}
.tag-font {
  font-size: var(---tag-font) !important;
}
.select-font {
  .ant-select-selection-item {
    font-size: var(---select-font) !important;
  }
}
.search-font {
  input {
    font-size: var(---select-font) !important;
  }
}

