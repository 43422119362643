.app {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-weight: 400;
}

.app a,
a:active,
a:hover {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
}

.app button {
  cursor: pointer;
}

.app button,
input {
  border: none;
  background-color: transparent;
}

.app ul {
  list-style: none;
}

.app ::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.app ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.app ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.16);
}

.dark-theme ::-webkit-scrollbar-track {
  background-color: initial;
}
