@import "../../../../../ThemeVariables.less";

.signup-wrapper {
  .signup-page-image {
    width: 100%;
    height: 100vh;
    background-image: url("./../../../../assets/img/signupPageImage.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  .form-footer-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fdfdfd;
    height: 100vh;
    overflow: scroll;

    .form-logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .bazar-logo {
        margin-top: 30px;
        width: 148px;
      }

      .welcome-text {
        font-family: "Roboto";
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #161616;
        margin-top: 20px;
        margin-bottom: 8px;
      }
      .instruction {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #646464;
        margin-bottom: 30px;
      }
      .signup-form label {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #646464;
      }
      .signup-form .ant-form-item-label {
        padding-bottom: 6px;
      }
      .signup-form .ant-form-item-required {
        width: 100%;
      }
      .firstname-form-item,
      .lastname-form-item,
      .company-url-form-item,
      .email-form-item {
        margin-bottom: 20px;
      }

      .password-form-item {
        margin-bottom: 10px;
      }

      .firstname,
      .lastname,
      .company-url,
      .email,
      .password {
        display: flex;
        align-items: center;
        padding: 5px 12px;
        width: 100%;
        // height: 42px;
        border: 1px solid #adadad;
        border-radius: 3px;
      }
      .signup-button {
        margin: 30px 0px;
        width: 100%;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: @primary;
        border-radius: 6px;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
      }
      .login-google-button {
        margin: 30px 0px;
        width: 100%;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        border-radius: 6px;
        border: 1px solid @primary;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: @primary;
      }
     
      .register-text {
        display: flex;
        justify-content: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #646464;
        margin-bottom: 10px;

        .register-link {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: @primary;
          margin-left: 7px;
        }
      }
    }

    .footer {
      .footer-text {
        // position: fixed;
        // bottom: 5px;
        padding-bottom: 30px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #646464;
      }
    }
  }
}

.popover-text {
  background-color: #000;
}

