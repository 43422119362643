@import "../../../../../ThemeVariables.less";

.imagine-process {
  h6 {
    font-weight: 700;
    color: #161616;
    margin-bottom: 0;
  }
  .ant-picker {
    width: 100%;
    border: 0;
    padding: 0;
    span.ant-picker-clear {
      top: 19px;
      right: 11px;
      font-size: 1rem;
    }
  }
  .ant-picker-focused {
    border: 0;
    box-shadow: none;
  }
  span.ant-picker-suffix {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: large;
  }
  textarea.ant-input {
    border: 1px solid #adadad;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  .ant-checkbox-input {
    top: -2px;
  }
  label.ant-checkbox-wrapper span {
    font: 1rem !important;
  }
  .fs-1 {
    font-size: 1.125rem;
    color: @gray;
  }
  span.default-msg {
    background: @secondary;
    padding: 10px;
    margin: 15px 0 0 0;
    display: inline-block;
    font-size: 1rem;
    border: 1px dashed @gray;
    border-radius: 5px;
  }
}

// .appointment-modal .ant-modal-footer {
//     background-color: #F2F9FD;
// }

.model-btn {
  width: 395px !important;
  padding-top: 10px;
}

