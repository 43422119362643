@import "../../../ThemeVariables.less";
.employer-tabs {
  .ant-tabs-tab-btn {
    color: @primary !important;
    font-weight: bolder !important;
    font-size: 18px;
  }
  .ant-tabs-ink-bar {
    background-color: @primary;
  }
}

.download-btn {
  .ant-dropdown-menu {
    background-color: #f2f9fd;
  }
  .ant-dropdown-menu-title-content a {
    font-weight: 600;
    color: @primary;
  }
}

.chart-box {
  background-color: #f2f9fd;
  padding: 30px 40px;
  border-radius: 12px;
}

.popover-form {
  .ant-select-selection-placeholder {
    color: @primary !important;
    font-weight: 600 !important;
  }
  .ant-select-selection-item {
    color: @primary !important;
    font-weight: 600 !important;
  }
  border-radius: 10px;
  .ant-select-selector,
  .ant-input,
  .ant-picker {
    background-color: transparent !important;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}
.ant-popover-inner {
  border-radius: 10px;
}

.ant-input-number {
  border-radius: 5px !important;
  width: 100%;
}

.ant-input-number:hover {
  border-color: @primary !important;
}

.ant-input-number:focus {
  border-color: @primary !important;
  box-shadow: none !important;
}

.basic-img-upload {
  .ant-upload-list-picture-card-container {
    margin: 0 15px 15px 0 !important;
  }
  .ant-upload-list-item-info {
    margin-top: 10px !important;
  }
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    width: 310px;
    height: 232px;
    .ant-upload-list-item {
      padding: 0;
      border-radius: 6px;
      overflow: hidden;
      border: none;
    }
    .ant-upload {
      flex-direction: column;
      font-weight: bolder;
      span {
        font-size: 40px;
        margin-bottom: 10px;
      }
    }
  }
}

.gap-10 {
  gap: 10px;
}

.temp-fix {
  visibility: hidden;
}

.ic-specialities {
  .ant-select-selector {
    height: 100% !important;
    min-height: 98px !important;
  }
  .ant-select-selection-overflow {
    margin-bottom: auto !important;
  }
}
.ref-code-details {
  color: #646464;
  font-size: 16px;
  font-weight: 500;
}
.ref-code {
  font-size: 18px;
  color: #929292cc;
  font-weight: 500;

  .ref-data {
    font-weight: 700;
    color: @primary;
  }
}

