@media screen and (min-width: 320px) and (max-width: 780px) {
  .mobile-card {
    margin: 0px 12px 0px 12px;
  }

  .search-divider {
    display: none !important;
    // visibility:hidden ;
  }

  .responsive-mt-20 {
    margin-top: 20px;
  }

  .global-search-wrapper {
    padding: 0px !important;
  }

  .search-head {
    display: unset;
  }

  .search-collaps {
    padding: 0px;
  }

  .search-back {
    height: 30px !important;
    margin-left: -10px 
  }

  .search-list .ant-list-item-meta {
    align-items: center !important;

    .ant-list-item-meta-avatar {
      margin-right: 5px !important;
    }
  }

  .search-result-p {
    // justify-content: left !important;
    width: 100% !important;
    // margin-left: -10px
    align-items: baseline !important;
    justify-content: unset !important;
    display: flex !important;
    // margin-left: -10px !important;
  }

  .search-collaps .ant-list-item-meta-content {
    margin-left: -5px;
    flex-direction: column !important;
    align-items: baseline;
    overflow: hidden;


    .ant-list-item-meta-avatar {
      margin-right: 10px !important;
    }

    .mobile-procedure-r {
      flex-direction: column !important;
      h4.ant-list-item-meta-title {
        line-height: 12px !important;
      }
    }
  }

  .search-list .ant-list .ant-avatar {
    width: 40px !important;
    height: 40px !important;
  }

  .search-list .ant-list-item-meta-content h4.ant-list-item-meta-title {
    margin-bottom: -5px !important;
    margin-left: 10px;
  }
}

@media screen and (min-width: 760px) and (max-width: 1024px) {
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
}

