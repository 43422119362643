@import "../../../../ThemeVariables.less";

.react-chatbot-kit-chat-header {
  display: none !important;
}
.react-chatbot-kit-chat-header {
  display: none !important;
}
.react-chatbot-kit-chat-container {
  box-shadow: 2px 2px 20px rgba(154, 154, 154, 0.25);
  width: 600px;
}
.react-chatbot-kit-chat-bot-message-container
  .react-chatbot-kit-chat-bot-message {
  margin-left: 50px;
}
.react-chatbot-kit-chat-bot-message-container
  .react-chatbot-kit-chat-bot-avatar
  ~ .react-chatbot-kit-chat-bot-message {
  margin: 0 !important;
}
.react-chatbot-kit-chat-container:before {
  height: 50px;
  width: 100%;
  content: "Delphi Support";
  position: absolute;
  top: -50px;
  line-height: 30px;
  background: @primary;
  padding: 10px !important;
  color: #fff;
  border-radius: 6px 6px 0 0;
}
.react-chatbot-kit-user-chat-message {
  background-color: @secondary !important;
}
.support-modal {
  .ant-modal-header,
  .ant-modal-footer,
  button.ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none;
  }
  .ant-modal-mask {
    background-color: transparent !important;
  }
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.support-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: @primary;
  border-radius: 40px;
  border-color: @primary;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    background-color: @primary;
  }
  img {
    width: 35px;
  }
}
.react-chatbot-kit-chat-container {
  position: fixed;
  right: 40px;
  bottom: 90px;
}

.ant-modal {
  bottom: 80px;
  top: unset;
}

.desired-modal {
  .ant-modal-content {
    border-radius: 10px;
  }

  span.anticon.anticon-close.ant-modal-close-icon {
    background: @primary;
    padding: 4px;
    border-radius: 20px;
    color: #ffffff;
  }
}

.user-chat {
  // border-radius: 10px 10px 0px 0px;
  border-radius: 10px;
  border: 2px solid @primary !important;
  // background: @primary !important;
  // color: #fff !important;

  .ant-card-body {
    padding: 0px;
  }
}

.user-chat-modal {
  padding: 30px;
  background: @primary !important;
  color: #fff !important;
  h2 {
    color: #fff !important;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    color: #d6d6d6;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .user-chat-assistance {
    flex-direction: column;
    justify-content: center;
  }
}

.chat-upload {
  display: flex;
  align-items: center;
}
.chat-input {
  border: 1px solid @primary;
  .avatar-uploader {
    display: flex;
    align-items: center;
  }
  img {
    padding: 0px 10px 0px 10px;
  }
  // border: 1px solid @primary;
  // background: red;
  // border-radius: 5px;
  .ant-input {
    padding: 20px;
    border-right: 0px !important;
    // border-radius: 0px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 10px;
    border: 0px !important;
  }
}

.user-chat-convo {
  padding: 30px;
  .right-chat {
    display: flex;
    justify-content: flex-end;

    p {
      background: #f3f7f8;
      padding: 10px;

      color: #161616;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .left-chat {
    width: 80%;
    margin-top: 10px;

    p {
      background: #f0f0f0;
      padding: 10px;
      color: #161616;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }
}

@animationTime: 0.8s;
@dotSize: 20px;

.user-chat-loader span {
  display: inline-block;
  vertical-align: middle;
  width: @dotSize;
  height: @dotSize;
  background: black;
  border-radius: @dotSize;
  animation: loader @animationTime infinite alternate;
}
.user-chat-loader span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.user-chat-loader span:nth-of-type(3) {
  animation-delay: 0.6s;
}
@keyframes loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

.system-chat {
  background: none !important;
  align-self: center !important;
  font-style: italic;
  font-size: 12px;
  font-weight: 800 !important;
  color: #8b8b8b !important;
  max-width: 100% !important;
}

.chat-pdf {
  margin-bottom: -10px;
  margin-left: -2px;
  p {
    margin-bottom: 0px !important;
  }
}

