@import "../../../ThemeVariables.less";
.user-imges {
  margin-top: -4px !important;
  margin-right: 10px !important;
  // width: 40px !important;
  // height: 40px !important;
  max-width: 40px;
  max-height: 40px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.navtab {
  display: flex;
  justify-content: space-between;
}
.navbar .ant-col.ant-col-20 {
  padding: 0 !important;
}
.navbar {
  padding: 0 0 0px 0;
}

.star-rating {
  display: inline-block;
}

.star {
  cursor: pointer;
  transition: fill 0.3s ease;
  margin-right: 15px;
}

.review-star {
  transition: fill 0.3s ease;
  // margin-right: 10px;
}
.review-card {
  .ant-tooltip-inner {
    padding: 15px !important;
  }
}
.feedback-comment {
  color: #000;
  font-size: 14px;
  font-weight: 550;
  margin-bottom: 0px;
  margin-left: 5px;
}
.feedback-review {
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;

  color: #161616;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 0px;
}

.star.selected {
  fill: #ffdb54; /* Change the fill color to yellow for selected stars */
}

.review-star.selected {
  fill: #ffdb54; /* Change the fill color to yellow for selected stars */
}

header.ant-layout-header {
  display: flex;
  background: #fff;
  height: auto;
}
.ant-layout {
  background-color: #eaf0f2;
  // height: 100vh;
  min-height: 100vh;
}
img.address-icon {
  margin: -5px 8px 0 0;
}
img.notificatioin-icon {
  width: 63px;
  padding: 0 20px;
  border-right: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}
.navbar li.ant-menu-overflow-item.ant-menu-item {
  padding: 5px !important;
  height: 70px;
  line-height: 60px;
  cursor: default;
}
.main-logo {
  height: 40px;
  // margin-top: 15px;
}
img.user-img {
  // width: 40px;
  // height: 40px;
  // margin-right: 10px;
  // margin-top: -10px;
  object-fit: cover;
}
.userName span.ant-menu-title-content {
  font-size: 1.125rem;
  color: #161616;
  font-weight: 700;
}
.navbar
  ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  width: 100%;
  justify-content: end;
}
.nav-btn {
  width: 100%;
  .divider {
    height: 21px;
    border-left: 1.5px solid #d9d9d9;
  }
  .ant-menu-submenu {
    padding: 0 6px !important;
  }
}
li.ant-menu-item.ant-menu-item-only-child.h-30 {
  height: 30px !important;
  background-color: #fff !important;
}

.nav-map {
  &:hover {
    cursor: pointer;
  }
}
.nav-address span {
  color: #636363;
  font-weight: 500;
}
.navbar .ant-menu-horizontal {
  border-bottom: 0 !important;
}
.logout-btn {
  border: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 600;
  color: #000 !important;
}
.menu-icon {
  display: none;
}

@media screen and (max-width: 576px) {
  header.ant-layout-header {
    padding: 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin: 10px 0;
    a {
      display: flex;
    }
    .nav-btn {
      display: none;
    }
  }

  img.main-logo {
    margin-left: 15px;
    height: 30px;
  }
}
.main-haeder {
  width: 100%;
  padding: 0;
}
section.header {
  width: 100%;
  background: #fff;
  position: sticky;
  z-index: 11;
  top: 0;
  box-shadow: 0 0 9px rgb(0 0 0 / 20%);
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.adminLayout {
  .sidebar {
    background-color: #00274c;
    .sidebar-wrapper {
      height: calc(100vh - 67px);
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .ant-menu-submenu-arrow {
        color: #000;
        width: 20px;
      }

      .ant-menu-submenu-title {
        margin-top: 0px !important;
        span {
          color: #000 !important;
          font-weight: 600;
        }
      }

      .ant-menu-item-only-child {
        background: #fff !important;
        margin-bottom: 10px !important;
        margin-top: -20px !important;
        a {
          color: #000 !important;
        }
      }

      .admin-chat-divider {
        width: 0px;
        border: 2px solid #63c0da;
        height: 20px;
      }
    }
    .logo {
      text-align: left;
      padding: 20px 20px 0 20px;
    }
    .ant-menu {
      margin-top: 25px;
      background-color: #00274c;
      border-right: 0 !important;
      .ant-menu-title-content {
        color: white;
      }
      a {
        color: white;
        font-weight: 500;
      }
    }
    img.ant-menu-item-icon {
      width: 21px;
      margin-right: 10px;
    }
    .ant-menu-item-selected {
      background-color: #ffffff;
      span,
      a {
        color: #000;
      }
      img {
        filter: grayscale(100%) brightness(0%) contrast(100%);
      }
    }
    .ant-menu-inline .ant-menu-item-selected::after {
      opacity: 0 !important;
    }
    .ant-menu-item {
      padding-left: 20px !important;
      padding-right: 20px !important;
      margin-bottom: 15px;
    }
    .ant-layout-footer {
      text-align: center;
      // position: absolute;
      // bottom: 0;
      background-color: transparent;
      padding: 24px 20px;
      color: #72a0be;
    }
  }
  .ant-layout-header {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    .nav-btn {
      ul {
        display: flex;
        justify-content: flex-end;
      }
      li {
        padding: 0 10px;
      }
      .notificatioin-icon {
        border-left: 0;
      }
    }
  }
  .ant-layout-content {
    overflow: scroll;
    height: 90vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .site-layout-background {
    min-height: 85vh !important;
    border-radius: 11px;
  }
}

.chat-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px !important;
  height: 60px !important;
  background-color: @primary;
  img {
    height: 25px;
  }
  &:hover {
    background-color: @primary;
    border-color: @primary;
  }
}

.ant-menu-submenu-title {
  padding-top: 4px !important;
}

.invite-emp {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: @primary;
}

.blue-btn-invite {
  width: 122px;
  height: 46px;
  background-color: @primary;
  border-color: @primary;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  &:hover,
  &:focus {
    background-color: transparent;
    color: @primary;
    border-color: @primary;
  }
}

.invite-select .ant-select-selector {
  height: 114px;
}

.invite-select .ant-select-selection-overflow {
  margin-top: -80px;
}

button.ant-btn.ant-btn-default.invite-btn-blue {
  border: 1px solid @primary;
  border-radius: 6px;
  height: 40px;
  color: @primary;
  background-color: transparent;
  font-weight: 600;
}

ul.status {
  list-style: none;
  margin: 0;
  padding-left: 16px;
}

ul.status li::before {
  content: "\2022";

  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.img-preview-container {
  display: inline-block;
  position: relative;
}

.img-preview {
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
}

.img-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.download {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.download i {
  font-size: 20px;
  color: #007bff;
}

.download a {
  text-decoration: none;
}

.ant-row.gap-10.mtr-20.download-btn {
  margin-top: -43px;
}

.delete-propmt {
  margin: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: @primary !important;
}

.last-login {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #837f7f;
}

.delete-headline {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: @primary;
}

.image-preview {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  position: relative;
  max-width: 250.43px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview-img {
  object-fit: contain;
  position: relative;
  display: flex;
  height: 180px;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.preview-image {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: opacity 0.3s;
}

.image-preview .hide-icon {
  display: none;
}

.image-preview:hover .hide-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #000;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.5rem;
  z-index: 2;
}

.navbar-content {
  border-right: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}

sup.ant-scroll-number.ant-badge-count {
  margin-right: 20px;
}

.bold-font span.ant-select-selection-item {
  font-weight: 500 !important;
}

.center-btn {
  text-align: center;
}

.inherit-btn {
  height: 35px !important;
  margin-left: 0 !important;
  height: inherit !important;
}

.action-icons {
  height: 35px;
}

.spinner-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.hover-thumb:hover {
  cursor: pointer;
}

.add-more-reviews {
  margin-top: 0px;
  color: @primary;
  font-weight: 500;
}

.editor-border {
  border-radius: 5px;
  border: 0.5px solid grey;
  padding: 10px;
  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 2rem !important;
  }
}

.editor-email {
  border-radius: 5px;
  border: 0.5px solid grey;
  background: #fff;
  padding: 10px;
  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 2rem !important;
  }
}

.service-image-preview {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  position: relative;
  // max-width: 250.43px;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-image-preview-img {
  object-fit: contain;
  position: relative;
  display: flex;
  height: 180px;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.service-preview-image {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: opacity 0.3s;
}

.service-image-preview .hide-icon {
  display: none;
}

.service-image-preview:hover .hide-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #000;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.5rem;
  z-index: 2;
}

.bottom-padding {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.app-card {
  .ant-card-body {
    padding-top: 0px !important;
  }
}

.employer-app-card {
  .ant-modal-body {
    padding-top: 0px !important;
  }
}

.employer-app-card-prompt {
  .ant-modal-body {
    // padding-top: 10px !important;
    // padding-bottom: 10px !important;
    padding: 30px !important;
  }
}

.app-card-bottom {
  .ant-card-body {
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.col-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.col-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.employer-card {
  .ant-card-body {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

.grey-color {
  background: #e0e0e0 !important;
  span {
    color: #000000 !important;
  }
}

.grey-color:hover {
  background: @primary !important;
  span {
    color: #fff !important;
  }
}

.invite-dep-title {
  font-size: 18px;
  font-weight: 400;
}

.nav-text {
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
}
// .styled-textarea::before {
//   content: "Requested By: ";
//   color: blue;
//   font-size: 16px;
// }

// .styled-textarea::before:nth-of-type(100) {
//   content: "Appointment For: ";
//   color: blue;
//   font-size: 16px;
// }

.app-title {
  margin-bottom: 14px !important;
  font-size: 20px;
  color: #263238;
  font-weight: 700;
}

.app-tracking {
  margin-bottom: 10px !important;
  font-size: 16px;
  color: #263238;
  font-weight: 700;
}
.app-title-last {
  margin-bottom: 9px !important;
  font-size: 20px;
  color: #263238;
  font-weight: 700;
}

.app-tabs {
  .ant-tabs-tab {
    margin: 10px 0 0 0 !important;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
  }
  .ant-tabs-tab {
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
  .ant-tabs-content-holder {
    margin-top: -65px !important;
  }
  .ant-tabs-tab-btn {
    width: 181px;
    background: #f8f8f8;
    border: 1px solid #e0e0e0;
    padding: 14px 10px;
    color: #161616 !important;
    border-radius: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    // padding-left: 0px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      background-color: #f2f9fd;
      &:hover {
        background-color: #f2f9fd;
      }
    }
  }
}

.appointment-select .ant-select-selector {
  height: 80px;
}

.appointment-select .ant-select-selection-overflow {
  margin-top: -45px;
}

.send-modal {
  .ant-modal-body {
    padding: 0px 30px 30px 30px;
  }
}

.cc-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: -50px;
  /* z-index: unset; */
  position: inherit;
  margin-right: 10px;
}

.rdw-link-modal {
  height: unset !important;
}

.userp-version {
  display: flex;
  justify-content: center !important;
  margin-bottom: 10px;
}

.ant-table-wrapper.common-table.hide-th {
  margin-top: 0px !important;
}

// .parent-table {
//   tr.ant-table-expanded-row.ant-table-expanded-row-level-1 {
//     background: red;
//   }
// }
.hide-th {
  thead {
    background-color: red;
    margin-top: -1000px;
  }
  // td.ant-table-cell {
  //   background: #F4F4F4 !important;
  // }
  table {
    border: none !important;
    background: #f4f4f4 !important;
  }
  .ant-table-cell {
    background: #f4f4f4 !important;
  }

  .ant-table-container {
    background: #f4f4f4 !important;
    border: none !important;
  }
  tbody {
    // background-color: #E0E0E0 !important;
    background: #f4f4f4;
    // font-weight: bolder;
  }
  .ant-table-cell.ant-table-selection-column {
    // display: table-column !important;
    visibility: hidden !important;
  }
  // .ant-table-cell {
  //   padding-top: 0px !important;
  // }

  .ant-table-thead {
    // visibility: hidden;
    display: none;
  }
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background-color: #f4f4f4 !important;
}

.filter-dropdown-list {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 35px !important;
    border: 1px solid @primary !important;
    display: inline-flex !important;
    // justify-content: center !important;
    align-items: center !important;
    font-weight: 600;
  }
  .ant-select-item-option-content {
    font-weight: 500;
    color: #263238 !important;
  }

  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border: 1px solid @primary !important;
    border-radius: 6px !important;
    // padding: 5px;
  }
}

.remove-height {
  .ant-input-number-input {
    height: unset !important;
    border: unset !important;
    // border: 1px solid #adadad!important;
    width: 100%;
  }
}
.ant-input-number {
  border: 1px solid #adadad !important;
  width: 100%;
}

.ant-input-number:hover {
  border-color: @primary !important;
}

.ant-input-number:focus {
  border-color: @primary !important;
  box-shadow: none !important;
}

.ant-menu-submenu-popup {
  li {
    margin-top: 0px !important;
    margin-bottom: 0px !important ;
  }
}

// .ant-menu-submenu-title {
//   display: flex !important;
//   align-items: center !important;
//   margin-top: 0px !important;
//     margin-bottom: 0px !important;
// }

.joyride-tour-heading {
  text-align: start;
  margin-top: -20px;
  display: flex;
  line-height: 24px;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
}
.joyride-tour-details {
  text-align: start;
  padding-top: 10px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
}
.joyride-tour-numbers {
  text-align: start;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -25px;
  font-weight: 700;
  margin-bottom: -30px;
}
.joyride-tour-numbers-disable {
  color: #87a9c0;
}
.joyride-tour-navbtn {
  color: #63c0da;
  text-align: start;
  cursor: pointer;
  font-weight: 700;
}
.joyride-tour-navbtn-prev {
  color: #87a9c0;
  text-align: start;
  cursor: pointer;
  font-weight: 700;
  margin-right: 10px;
}
.joyride-tour-end {
  cursor: pointer;
}

// .header-child-menu {
//   .ant-menu-submenu-title {
//     display: flex !important;
//     /* justify-content: center; */
//     align-items: center !important;
//     margin-bottom: 0 !important;
//     padding-top: 0px !important;
//     margin-top: 0px !important;
//     padding-bottom: 0px !important;
//   }
//   // .ant-menu-submenu-title
//   li.ant-menu-submenu.ant-menu-submenu-vertical {
//     display: flex !important;
//     /* justify-content: center; */
//     align-items: center !important;
//     margin-bottom: 0 !important;
//     padding-top: 0px !important;
//     margin-top: 0px !important;
//     padding-bottom: 0px !important;
//   }

// }

.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-top: 0 !important;
  margin-top: 0;
  padding-bottom: 0 !important;
  margin-bottom: 0;
}

// .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title span,a{
// color: #000000 !important;
// }

.user-select-none {
  pointer-events: none;
  cursor: default;
  user-select: none;
}

.submenu-custom .ant-menu-submenu-popup {
  left: auto !important;
  right: 0;
}

// .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop {
//   left: 782px !important;
//   top: 256.5px !important;
// }e

.responsive-menu-list {
  .logout-btn {
    color: #fff !important;
    font-weight: 500 !important;
  }
  .ant-menu-item span {
    color: #fff !important;
    font-weight: 500 !important;
  }
}

// .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop {
//   left: 805px !important;
// }

.user-outline-responsive {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 40px;
  height: 40px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.hr-invite-modal {
  p {
    font-weight: 500 !important;
  }
  span {
    font-weight: 600 !important;
  }
  .ant-modal-body {
    padding: 40px 10px 40px 10px !important;
  }
}

.copy-link {
  .ant-input-group {
    display: flex !important;
  }
  .ant-input-group-addon {
    margin-left: 10px;
    border: 1px Solid @primary;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
  }
}

.hide-clipboard {
  display: none;
}

.responsive-card {
  min-height: 80vh;
}

.contact-us-modal {
  .ant-card-body {
    padding: 5px 5px 5px 5px !important;
  }
  h6 {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
  }
  .contact-description {
    color: #646464;
    font-weight: 500;
    margin: 10px 0px 10px 0px;
  }
  p {
    margin-top: 0px;
    font-weight: 500;
    color: @primary !important;
    line-height: 12px;
    margin-bottom: 25px;
  }
  .doctor-profile {
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.invite-btn button {
  background: #fff;
  color: @primary;
  border: none;
}

.invite-btn button:hover,
.invite-btn button:focus {
  background: #fff;
  color: @primary;
  border: none;
}

@media screen and (min-width: 320px) and (max-width: 780px) {
  .hr-invite-modal .ant-modal-body {
    padding: 0px 10px 30px 10px !important;
    h6 {
      margin-bottom: 0px !important;
    }
  }
  .r-mt-20 {
    margin-top: 20px !important;
  }
}

.ind-invite {
  .suggest-modal .ant-modal-content .ant-modal-body {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .ant-modal-body {
    padding: 0px;
  }
}
.user-notify-header {
  background-color: #00406a;
  margin: -20px -20px 0 -20px !important;
  padding: 20px;
  align-items: center;

  h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  h5 {
    color: #ffcb05;
    font-size: 12px;
    font-weight: 550;
    margin-bottom: 0px;
    text-align: right;
  }
}
.user-notification {
  .ant-tooltip-arrow {
    display: none !important;
  }
  .ant-tooltip-inner {
    border: 1px solid #00406a;
    padding: 20px 20px 0px 20px;
    width: 500px !important;
    margin-top: 10px !important;
  }
}

.empty-img {
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none !important;
  }
  .ant-spin-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ant-empty-image {
    height: 60px !important;
    img {
      width: 50px !important;
    }
  }
}

.service-detail-tab {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    color: @primary !important;
    text-shadow: 0 0 0.25px currentcolor;
  }
  .ant-tabs-ink-bar {
    background: @primary !important;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: @primary !important;
  color: @primary !important;
}

.ant-radio-inner::after {
  background-color: @primary !important;
}

.emp-table {
  table th.ant-table-cell.ant-table-selection-column {
    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
  .ant-checkbox-wrapper {
    margin-left: -4px;
  }
}

// .ant-menu-submenu-title {
//   margin-top: 0px !important;
//   span {
//     color: #000 !important;
//     font-weight: 600;
//   }
// }

// .ant-menu-item-only-child {
//   background: #fff !important;
//   margin-bottom: 10px !important;
//   margin-top: -20px !important;
//   a {
//     color: #000 !important;
//   }
// }

// .admin-chat-divider {
//   width: 0px;
//   border: 2px solid #63c0da;
//   height: 20px;
// }

.ant-skeleton-element {
  display: contents !important;
}

.ant-skeleton-button {
  margin: 0px !important;
}

.chat-notify-icon {
  .ant-badge-status-dot {
    width: 20px;
    height: 20px;
    background-color: red !important;
    margin-right: -20px !important;
    margin-top: -60px;
    margin-left: -15px !important;
  }
}

.chat-count-badge {
  .ant-badge {
    height: 22px;
  }
}

.chat-category-active {
  background: #f2f9fd !important;
}

