@media screen and (min-width: 320px) and (max-width: 780px) {
  .procedure-prices {
    flex-direction: row !important;
  }

  .procedure-detail-tabs .ant-tabs-nav {
    top: -90px !important;
    left: 2px !important;
}

  .reward-amount {
    color: #ffffff !important; 
  }
 
  .procedure-details .find-doc-btn {
    height: 40px !important;
  }
  .pro-mt-15 {
    margin-top: 0px !important;
  }
  .p-search-head {
    display: block !important;
  }

  .show-procedure-btn {
    display: block !important;
    .find-doc-btn {
      height: 42px !important;
    }
    // padding-bottom: 0px !important;
  }
  .hide-procedure-btn {
    display: none !important;
  }
  .reward-bottom-btn {
    position: fixed !important;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    z-index: 999 !important;
  }
  .procedure-badge {
    display: none;
  }
  .procedure-card .main-search {
    margin-top: 5px !important;
  }

  .procedure-detail-tabs {
    .ant-tabs-nav {
      position: absolute !important;
    }
  }

  .reward-img-title {
    max-width: 40% !important;
  }

  .left-col {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 760px) and (max-width: 1024px) {
}

